.private-policy-title,
.terms-policy-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  padding: 3% 1%;
}

.terms-conditions-title,
.privacy-title {
  font-size: 27px;
  font-weight: 600;
  position: relative;
}

.terms-conditions-title::after,
.privacy-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(32, 147, 241);
  left: 25%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
  box-shadow: 0px 7px 15px 0px #b9b9b9;
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-moz-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-o-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.policy-titles {
  font-size: 22px;
  font-weight: 600;
}

.sub-content {
  margin-left: 25px;
}

.data-header {
  margin-top: 10px;
}

.data-collected-content {
  margin-left: 5px;
}

.privacy-policy-content,
.terms-conditions-content {
  line-height: 25px;
  text-align: justify;
  font-size: 14px;
  color: #494949;
}

.personal-data-list,
.responsibility-warranty-lists {
  margin-left: 40px;
}

.sub-content-paragragh {
  margin-left: 25px;
}

.email-style {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin-left: 35px;
}
.terms-contact-separator {
  padding-left: 10px;
  padding-right: 10px;
}
.terms-email-separator {
  padding-left: 16px;
  padding-right: 10px;
}

.policy-modal ol,
.policy-modal ul,
.policy-modal p {
  font-family: "Montserrat", sans-serif;
}

.close-button {
  display: block;
  margin: 0;
  padding: 7px 20px;
  border: 1px solid transparent;
  background-color: rgb(16, 108, 200);
  color: rgb(255, 255, 255);
  font-weight: 600;
  height: 100%;
  box-shadow: 2px 3px 8px rgb(184, 184, 184);
  transition: 0.3s all;
}

.close-button:hover,
.close-button:focus {
  outline: none;
}

.close-button:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

@media (min-width: 575px) and (max-width: 765px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0.5rem auto;
  }
}

@media (min-width: 765px) and (max-width: 990px) {
  .policy-modal .modal-dialog {
    max-width: 80%;
    margin: 0.75rem auto;
  }
}

.policy-modal .modal-body {
  padding: 10px 40px;
}

@media (max-width: 555px) {
  .policy-modal .modal-body {
    padding: 20px;
  }

  .sub-content {
    margin-left: 20px;
  }

  .personal-data-list {
    margin-left: 25px;
  }

  .responsibility-warranty-lists {
    margin-left: 25px;
  }

  .email-style {
    margin-left: 25px;
  }

  .policy-titles {
    font-size: 20px;
  }

  .terms-policy-title::after {
    width: 240px;
  }

  .private-policy-title::after {
    width: 145px;
  }

  .terms-conditions-title {
    font-size: 25px;
    font-weight: 600;
  }

  .privacy-title {
    font-size: 25px;
    font-weight: 600;
  }
}
