.scroll-fade-in {
  background-image: linear-gradient(
    to right top,
    #6bd17c,
    #00cfa8,
    #00c8d1,
    #00bced,
    #00adf7,
    #44a5f6,
    #659cf3,
    #7f93ec,
    #7993f0,
    #7193f3,
    #6994f7,
    #5f94fb
  );
  border: none;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  line-height: 5px;
  border-radius: 50%;
  z-index: 999;
  transition: opacity 1s ease-in;
  opacity: 1;
}
.scroll-fade-in,
.scroll-fade-out,
.scroll-fade-in:focus,
.scroll-fade-out:focus {
  outline: none;
}
.scroll-fade-in > svg,
.scroll-fade-out > svg {
  font-size: 40px;
}

.scroll-fade-in:hover > svg {
  animation: bounce 1s infinite 0.5s ease-in-out;
}

.scroll-fade-out:hover > svg {
  animation: none;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(-6px);
  }
}

.scroll-fade-in:hover,
.scroll-fade-in:focus,
.scroll-fade-out:focus,
.scroll-fade-out:hover {
  outline: none;
}

.scroll-fade-out {
  background: rgb(98, 204, 223);
  border: none;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  line-height: 5px;
  border-radius: 50%;
  z-index: 999;
  transition: opacity 1s ease-out;
  opacity: 0;
}
