.courses-list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  overflow-x: hidden;
  padding: 30px;
}

.courses-list.empty {
  height: auto;
  overflow: hidden;
  min-height: auto;
  max-height: auto;
  padding: 5px;
}

.courses-list.grid {
  flex-wrap: wrap;
  flex-direction: row !important;
}

.courses-list.list {
  flex-direction: column !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: 0 0 1px rgba(51, 51, 51, 0.685);
  box-shadow: 0 0 1px rgba(51, 51, 51, 0.685);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(126, 126, 126, 0.8);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(99, 99, 99, 0.4);
}

.courses-header {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.search-courses {
  flex: 0 1 50%;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}

.search-course-container {
  position: relative;
}

.search-course-container .clear-search-input {
  position: absolute;
  right: 6px;
  top: 5px;
  background-color: #e1f2ff;
  border: none;
  padding: 3px 8px;
  border-radius: 4px;
  color: #525252;
  font-size: 15px;
}

.search-courses .all-course-search {
  width: 100%;
  padding: 9px 18px;
  border: 1px solid #e4e4e400;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: 2px 4px 20px rgb(222 222 222);
  color: #403c3c;
  background-color: white;
}

.search-courses .all-course-search::placeholder {
  font-size: 16px;
  color: rgb(107, 105, 105) !important;
  font-style: italic;
}

.search-courses .search-results-info {
  color: rgb(83, 83, 83);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: none;
}

.search-courses .search-results-info.show {
  display: block !important;
}

.search-courses .search-results-info strong {
  font-size: 18px;
}

.search-courses .search-results-info small {
  margin: 0px 5px;
}

.search-courses .all-course-search::placeholder {
  font-size: 15px;
  color: rgb(85, 85, 85);
}

.search-courses .suggestions {
  position: absolute;
  width: 100%;
}

.course-search {
  padding: 5px;
  height: 45px;
  display: flex;
  align-items: center;
  border: none;
  font-size: 15px;
  border-radius: 30px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: -1px 2px 8px rgba(224, 224, 224, 0.741);
}

.courses-header .course-action {
  display: flex;
  display: -webkit-flex;
  flex: 0 0 200px;
}

.filter-option {
  position: relative;
  padding: 7px 15px;
  color: rgb(128, 128, 128);
  cursor: pointer;
}

.course-view-option {
  display: flex;
  display: -webkit-flex;
  padding: 2px 15px;
  box-shadow: 2px 4px 20px rgb(222 222 222);
  border-radius: 4px;
  height: 50px;
}

.course-view-option .grid-view,
.course-view-option .list-view {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 7px;
  font-size: 30px;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  outline: none;
}
.course-view-option .grid-view:focus,
.course-view-option .list-view:focus {
  outline: none;
}

.course-view-option svg {
  padding: 5px;
  color: rgb(142, 142, 142);
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.course-view-option .grid-view:not(.active):hover svg,
.course-view-option .list-view:not(.active):hover svg {
  color: rgb(135, 135, 136);
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
  box-shadow: 2px 0px 11px rgb(230, 230, 230);
}

.course-view-option .grid-view.active svg,
.course-view-option .list-view.active svg {
  color: rgb(76, 137, 249);
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
  box-shadow: 2px 0px 11px rgb(230, 230, 230);
}

.filter-icon {
  height: 40px;
  width: 40px;
  font-size: 25px;
  padding: 1px 7px;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

.filter-icon:hover {
  color: rgb(135, 135, 136);
  background-color: rgb(241, 240, 240);
  border-radius: 30px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  min-width: 150px;
  box-shadow: 4px 8px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin: 0px;
  padding: 0px 25px;
  top: px;
  left: -110px;
  border-radius: 5px;
  padding: 15px 3px;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: rgb(78, 78, 78);
  padding: 5px 12px;
  display: block;
  font-size: 15px;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {
  background-color: rgb(243, 243, 243);
}

/* Show the dropdown menu on hover */
.dropdown .dropdown-content.show {
  display: block;
  cursor: pointer;
}

/* No course container styles */
.no-course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.no-course-container .title {
  position: absolute;
  font-size: 45px;
  color: #e0e0e0;
  font-weight: 700;
  letter-spacing: 0.8px;
  top: 40px;
}

.no-course-container .no-course-img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* load more */
.btn-load-more {
  padding: 10px 20px;
  box-shadow: 1px 7px 10px #cecece;
  background-color: #fff;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #7b7b7b;
  -webkit-transition: 0.4s all ease-in-out;
  -moz-transition: 0.4s all ease-in-out;
  -o-transition: 0.4s all ease-in-out;
  transition: 0.4s all ease-in-out;
}

.btn-load-more .fa-spinner {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-load-more:hover,
.btn-load-more:focus {
  background-color: rgb(43, 141, 253);
  color: #f7f7f7;
  box-shadow: 1px 7px 20px #b4b3b3;
  outline: none;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

@media screen and (max-width: 991.98px) {
  .courses-list.grid {
    justify-content: center;
  }
}

@media screen and (max-width: 572px) {
  .no-course-container .title {
    font-size: 20px;
    top: 20px;
  }

  .courses-header {
    display: block;
  }

  .search-courses {
    padding: 10px 0px;
  }

  .courses-header .course-action {
    flex: 0 0 0px;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}
