.oep-contact-section {
  padding: 15px 10px;
}

.oep-contact-section {
  background-color: #f7f7f7;
}

.top-section-contact {
  padding: 10%;
}

.top-contact-heading {
  text-align: center;
  text-align: -webkit-center;
  color: rgb(33, 150, 243);
  padding-bottom: 24px;
  font-size: 40px;
  font-weight: 800;
}

.top-contact-content {
  padding-bottom: 0.5rem;
  font-weight: 500;
  font-size: 20px;
  color: rgb(142, 150, 165);
  text-align: center;
  text-align: -webkit-center;
  letter-spacing: 0.5px;
}

.top-contact-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 6%;
}

.fade-in {
  animation: fadeInRight ease 1.5s 0.5s forwards;
  /* IE 10+, Fx 29+ */
  -webkit-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Safari 4+ */
  -moz-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Fx 5+ */
  -o-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Opera 12+ */
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.contactIcon {
  padding-left: 8px;
  padding-right: 8px;
}

.card-section-query {
  border-radius: 7px;
  background-color: rgb(255 255 255);
  width: 100%;
  height: auto;
  padding: 15px;
  box-shadow: 2px 0px 20px #dedede;
}
.query-section-heading {
  text-align: center;
  text-align: -webkit-center;
  color: rgb(0, 0, 0);
  position: relative;
  font-weight: 700;
}

.query-section-heading::after {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  margin-left: 30%;
  border-radius: 15px;
  height: 3px;
  background-color: rgb(240, 79, 30);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-moz-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-o-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.query-section-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding-top: 15%;
}

.query-background {
  display: block;
  transform: scale(0.9);
  width: 100%;
  height: 100%;
}

.query-card-container {
  margin: 10px 0px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.query-card {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 280px;
  padding: 30px;
  border-radius: 7px;
  background-color: inherit;
  border: 1px solid transparent;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.query-card-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  padding: 25px 0px;
}

.query-card-title {
  text-align: center;
  text-align: -webkit-center;
  position: relative;
  font-size: 21px;
  font-weight: 700;
  color: rgb(113 115 119);
  letter-spacing: 0.5px;
}

.query-card-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 6px;
  background-color: rgb(48 202 144);
  left: 50%;
  bottom: -20px;
  transform: scaleX(1) translate(-50%, -50%);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
}

.query-form-contact {
  padding-top: 48px;
  padding: 48px 48px;
}

@media screen and (max-width: 567.98px) {
  .query-card-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 992px) {
  .query-section-image {
    padding-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .card-section-query {
    padding-left: 45px;
  }
}

@media screen and (max-width: 575px) {
  .card-section-query .query-form {
    padding-left: 35px;
  }
}

@media screen and (max-width: 575px) {
  .query-form-contact {
    padding: 0px;
    padding-top: 48px;
  }
}
