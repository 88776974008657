/* Remove below .temp while developing */
.mini-query-section {
    position: sticky;
    position: -webkit-sticky !important;
    top: 80px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 6px 20px #cfcece;
}

.beSticky {
    position: fixed;
    width: 368px !important;
    transform: translate(0, 10%);
    -webkit-animation: floatingCard 2.5s infinite ease-in-out;
    /* Safari 4+ */
    -moz-animation: floatingCard 2.5s infinite ease-in-out;
    /* Fx 5+ */
    -o-animation: floatingCard 2.5s infinite ease-in-out;
    /* Opera 12+ */
    animation: floatingCard 2.5s infinite ease-in-out;
}

.absolute {

    transition: 0.5s all ease;
    animation: fadeOut 1.5s ease-in-out;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.7;
    }

    75% {
        opacity: 0.4;
    }

    100% {
        position: absolute;
        opacity: 0;
    }
}

.beSticky:hover {
    animation: none;
    transform: translate(0px, 0px);
}

@keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 10px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-moz-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-o-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-webkit-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

.mini-query-sendicon {
    padding-left: 20px
}

.mini-query-input {
    border: none;
    width: 100%;
    background-color: none;
}


.mini-query-input::placeholder,

.mini-query-text-box::placeholder {
    font-style: italic;
}

.mini-query-fields {
    padding-top: 20px;
    border-bottom: 1px solid rgb(128, 128, 128);
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
}


.mini-query-submit-btn {
    padding: 10px 25px;
    color: rgb(82, 143, 255);
    background: transparent;
    border: 1px solid rgb(82, 142, 251);
    font-weight: 700;
    transition: 0.5s all ease-in-out;

}

.mini-query-submit-btn:focus {
    outline: none;
}

.mini-query-submit-btnicon {
    padding-left: 20px;
}


.mini-query-header {
    text-align: center;
    font-weight: 700;
    padding: 10px;
}

.mini-query-header::after {
    content: "";
    display: inline-block;
    position: relative;
    width: 45%;
    border-radius: 15px;
    height: 4px;
    background-color: rgb(82, 142, 251);
    left: -1%;
    bottom: 10px;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    animation: expandCenter 1s ease-in-out;
}

.mini-query-text-box {
    width: 100%;
    height: 100%;
    padding: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    max-height: 105px;
    min-height: 50px;
}

.mini-query-submit-btn {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);
    border-radius: 2px;
    box-shadow: 0 0 1px transparent;
}

.mini-query-submit-btn:before {
    content: '';
    position: absolute;
    border: rgb(82, 142, 251) solid 2px;
    border-radius: 2px;
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
    opacity: 0;
    transition-duration: .3s;
    transition-property: top right bottom left;
}

.mini-query-submit-btn:hover:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 1;
}

.mini-query-submit-btn:hover {
    background-color: rgb(82, 142, 251);
    color: rgb(255, 255, 255);
}

.mini-query-fields .react-tel-input {
    width: 100%;
    border: 0px solid transparent !important;
    background-color: transparent !important;
}

.mini-query-fields .react-tel-input .form-control {
    background-color: transparent !important;
    border: 0px solid transparent !important;

}

.mini-query-fields .react-tel-input .flag-dropdown {
    border: 0px solid transparent !important;
    background-color: transparent !important;
}