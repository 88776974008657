.detail-header {
    padding: 30px 0px;
}

.course-detail-title {
       color: #1a81f1;
    padding-bottom: 2rem;
    font-size: 35px;
    font-weight: 700;
}

.course-detail-content {
    padding-bottom: 0.25rem;
    font-weight: 400;
    font-size: 18px;
    color: rgb(102, 99, 99);
    letter-spacing: 0.5px;
    line-height: 1.6;
}

.course-detail-rating {
    font-weight: 600;
    font-size: 20px;
    position: relative;
}

.course-detail-rating>span {
    position: relative;
    top: 4px;
    margin-right: 10px
}

.discount-banner {
    padding: 15px;
    color: rgb(255, 255, 255);

    background: #ffb347;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ffcc33, #ffb347);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffcc33, #ffb347);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    animation: floatingCard 2.5s infinite ease-in-out;
    text-align: center;
    text-align: -webkit-center;
    margin: 15px 0px;
    border-radius: 5px;
    box-shadow: 1px 9px 11px #c2c2c2;
}

@keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 10px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-moz-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-o-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-webkit-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

.discount-banner .text {
    font-weight: 600;
    font-size: 1.5em;
}

.discount-banner .discount-rate {
    font-size: 1.7em;
}


.duration-sm {

    font-size: 1.4em;
    margin: 10px 0px 0px 0px;
    color: rgb(59, 59, 59);
}

.duration-sm strong {
    font-size: 1.4em;
    color: rgb(32, 156, 255);
}

.duration-sm label,
.duration-sm span {
    margin-right: 5px;
}

@media (max-width: 599px) {
    .course-detail-title {
        line-height: 2.5rem !important;
    }

    .duration-sm {
        font-size: 1.1em !important;
    }

    .discount-banner .text {
        font-size: 1.1em !important;
    }
}