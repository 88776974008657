.be-tutor-fbtn {
  display: none;
  background-image: linear-gradient(120deg, #f16a3f 0%, #ff9800 100%);
  border: none;
  color: white;
  position: fixed;
  top: 85px;
  right: 20px;
  height: 50px;
  width: 120px;
  line-height: 5px;
  border-radius: 25px;
  z-index: 999;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  cursor: pointer;
  outline: none;

  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
}
.be-tutor-fbtn:focus {
  outline: none;
}
.be-tutor-fbtn > svg {
  font-size: 20px;
}

.be-tutor-fbtn:hover > svg {
  animation: zoomInOut 1s infinite 0.5s ease-in-out;
}

.scroll-fade-out:hover > svg {
  animation: none;
}

@keyframes zoomInOut {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 1050.98px) {
  .be-tutor-fbtn {
    display: flex;
  }
}
