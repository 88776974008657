/* Below styles are fore temporary purpose and used to build the skeleten layout. */

.oep-course-section {
  padding: 15px 0px;
  background-color: #fbfbfb;
}

.courses-section-title {
  position: relative;
  font-weight: 700;
  max-width: 250px;
  width: fit-content;
  width: -moz-fit-content;
  left: 10px;
  font-size: 30px;
  color: #0b7ee0;
}

.courses-section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandToRight 1s ease-in-out;
}

@keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@-moz-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@-o-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

.courses-carousel-container {
  height: 350px;
}

#all-courses {
  padding-top: 80px;
}

@media screen and (max-width: 991.98px) {
  .courses-section-title {
    font-size: 1.5em;
  }
}
