.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /*16:9 Aspect Ratio*/
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 3px;
  background-color: rgb(230, 230, 230);
}

.responsive-skeleton-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}