/* Main styles */
.foot-section {
  height: auto;
  color: rgb(255, 255, 255);
  background: linear-gradient(to right, rgb(14, 13, 13), rgb(4, 1, 22));
  position: relative;
  padding: 3em;
}
.foot-section .companyLogo {
  transform: scale(3.5) translate(0px, 6px);
  margin-bottom: 15px;
}

.logo-image {
  width: 50px;
  height: auto;
}

.motto {
  flex-wrap: wrap;
  font-size: 13px;
  color: rgb(207, 207, 207);
}

.foot-flex-container {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.logo-container {
  padding: 0px 15px;
  width: 20%;
}

.foot-nav-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-evenly;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0px 15px;
  text-align: left;
}

.foot-nav-container div {
  padding: 0px 10px;
}

/* logo styles */
.logo-img {
  font-size: 20px;
  color: rgb(255, 255, 255);
}

/* Footer header styles */
.foot-sub-header {
  display: inline-block;
  color: rgb(255, 255, 255);
  margin-bottom: 5px;
  position: relative;
}

.foot-sub-header::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: rgb(232, 139, 0);
  position: absolute;
  top: 30px;
  left: 0px;
  border-radius: 1px;
}

.footer-nav-links {
  list-style-type: none;
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.button-nav-link {
  border: none;
  background-color: transparent;
  color: rgb(175, 175, 175);
}

.attribute {
  font-size: 11px;
  color: rgb(105, 105, 105);
}

.attribute-link {
  color: rgb(105, 105, 105);
}

.attribute-link:hover {
  text-decoration: none;
  color: rgb(240, 248, 255);
}

.footer-nav-item {
  margin: 0px 5px;
}

.bottom-info {
  display: flex;
  justify-content: flex-end;
  bottom: 0px;
  color: rgb(105, 105, 105);
}

/* Footer list stytles */

.footer-nav-item {
  cursor: pointer;
  position: relative;
  margin-bottom: 8px;
}

.footer-nav-item a,
.footer-nav-item button {
  font-weight: 400;
  position: relative;
  color: rgb(194, 194, 194);
  font-size: 15px;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -webkit-transition: 0.2s all;
}

.button-nav-link:hover {
  text-decoration: underline;
}

.button-nav-link:focus,
.button-nav-link:hover {
  outline: none;
}

.footer-nav-item a:hover,
.footer-nav-item button:hover {
  color: rgb(235, 235, 235);
}

.footer-nav-item.social a {
  text-decoration: none !important;
  display: block;
  margin-bottom: 5px;
}

.next-page-url a:hover {
  color: rgb(235, 235, 235);
  text-decoration: none;
}

/* social-links styles */
.social-links-container .footer-nav-item svg {
  font-size: 20px;
  color: rgb(197, 197, 197);
  transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -webkit-transition: 0.5s all;
}

.social-links-container .footer-nav-item svg:hover {
  color: rgb(238, 146, 9);
}

/* next page url */
.next-page-url {
  font-size: 18px;
}

.next-page-url a {
  color: rgb(207, 207, 207);
  font-size: 16px;
  padding-left: 10px;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  text-decoration: underline;
}

/* copyright info styles */
.copyright-section {
  bottom: 0px;
  padding-left: 10px;
}

.copyright-info {
  font-size: 12px;
  color: rgb(105, 105, 105);
}

@media screen and (max-width: 862px) {
  .foot-section {
    height: auto;
    font-size: 14px;
  }
  .bottom-info {
    justify-content: center;
  }
  .foot-flex-container {
    flex-direction: column;
    padding-bottom: 25px;
  }

  .logo-container {
    align-self: center;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    text-align: -webkit-center;
  }

  .next-page-section {
    align-self: center;
    padding-bottom: 30px;
  }

  .foot-nav-container {
    order: 2;
    padding-bottom: 30px;
  }
  .foot-section .companyLogo {
    transform: scale(3) translate(0px, 6px);
    margin-bottom: 25px;
  }
}

/* @media screen and (max-width:762px) {
  .foot-section {
    height: auto;
    font-size: 14px;
  }

  .foot-flex-container {
    flex-direction: column;
    padding-bottom: 25px;
  }

  .logo-container {
    align-self: center;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    text-align: -webkit-center;
  }

  .next-page-section {
    align-self: center;
    padding-bottom: 30px;
  }

  .foot-nav-container {
    order: 2;
    padding-bottom: 30px;
  }
} */

@media screen and (max-width: 562px) {
  .foot-section {
    text-align: center;
    font-size: 14px;
    padding: 32px 2px;
  }

  .foot-nav-container {
    flex-wrap: nowrap;
    font-size: 12px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-nav-links {
    align-items: center;
    justify-content: center;
  }
  .foot-sub-header::after {
    height: 3px;
    top: 18px;
  }

  .foot-sub-header {
    font-size: 15px;
  }
  .bottom-info {
    flex-direction: column-reverse;
  }
  .attribute {
    margin-bottom: 15px;
  }
  .logo-container {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }
}
