.courses-header-container {
  padding: 20px 0px !important;
  position: relative;
  background-image: url(../../../assets/images/coursesHeader.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.73);
  height: auto;
}

.courses-header-container::after {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73);
  opacity: 0.9;
}

.course-category-header {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0px 15px;
  height: auto;
}

.header-style {
  color: rgb(255, 250, 240);
  font-weight: 600;
}

.subtitle {
  position: relative;
  height: 75px;
  padding: 15px 0px;
  height: auto;
}

.header-subtile {
  padding: 10px 5px;
  color: rgb(245, 245, 245);
  font-style: italic;
  font-weight: 600;
}

.navpills {
  position: relative;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.course-pills-skeleton {
  margin: 7px;
}

.course-pills-skeleton span {
  border-radius: 30px;
}

.course-pills-bg {
  padding: 3px 20px;
  margin: 7px;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1.2px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  position: relative;
  overflow: hidden !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent !important;
  z-index: 10;
}

.course-pills-bg:hover {
  border: 1px solid transparent;
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}

.course-pills-bg::before {
  content: "";
  width: 0%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to top, rgb(68, 129, 235) 0%, rgb(4, 190, 254) 100%);
  position: absolute;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  left: -10%;
  opacity: 1;
  top: 0;
  z-index: -12;
  -moz-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6);
}

.course-pills-bg::after {
  content: "";
  width: 0%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to top, rgb(207, 217, 223) 0%, rgb(226, 235, 240) 100%);
  position: absolute;
  -ms-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
  left: -10%;
  opacity: 0;
  top: 0;
  z-index: -15;
  -webkit-transition: all 0.94s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  -moz-transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  -o-transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6);
}

.course-pills-bg:hover::before {
  opacity: 1;
  width: 116%;
}

.course-pills-bg:hover::after {
  opacity: 1;
  width: 125%;
}

.load-button {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2px;
  outline: none;
  margin: 10px;
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  text-align: center;
  text-align: -webkit-center;
  position: relative;
}

.load-button:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

.load-button:focus {
  outline: 0;
  box-shadow: none;
}



@media screen and (max-width:572px) {
  .course-pills-bg {
    font-size: 15px;
  }
}