.modes-training-section {
  padding: 30px 0px;
}

.mode-card-wrapper {
  width: 100%;
}

.mode-card-container {
  width: 320px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(65, 65, 65);
  box-shadow: 0px 0px 20px 11px #efececcc;
  border-radius: 5px;
  padding: 1.4rem;
  position: relative;
  transition: 0.2s all;
}

.flex-wrapper {
  flex-direction: row;
}

.mode-card-container:hover {
  box-shadow: 0px 0px 20px 10px #ebeaeacc;
  transform: translate(0px, -5px);
  -webkit-transform: translate(0px, -5px);
  -o-transform: translate(0px, -5px);
  -moz-transform: translate(0px, -5px);
}
.mode-card-container:hover .training-mode-brief {
  color: rgb(61, 58, 58);
}
/* header styles */
.training-mode-header {
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;
  margin-bottom: 15px;
  font-size: 1.3em;
  color: rgb(70, 70, 70);
}

.training-mode-brief {
  padding: 0px 15px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 25px;
  width: 100%;
  color: #a09f9f;
  text-align: -webkit-center;
  text-align: center;
  transition: color 0.5s ease-in-out;
}

.mode-training-doubts {
  display: flex;
  display: -webkit-flex;
  padding: 19px;
  /* padding-left: 18%; */
  font-size: 20px;
  font-weight: 700;
  color: rgb(169, 169, 169);
  letter-spacing: 1px;
  width: 100%;
  max-width: 760px;
  text-align: center;
  justify-content: center;
}

.mode-training-doubts a {
  text-decoration: none;
}

.mode-training-doubts a:hover .query-call-icon {
  -webkit-animation: shake 0.6s infinite 0.5s;
  /* Safari 4+ */
  -moz-animation: shake 0.6s infinite 0.5s;
  /* Fx 5+ */
  -o-animation: shake 0.6s infinite 0.5s;
  /* Opera 12+ */
  animation: shake 0.6s infinite 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media screen and (max-width: 991.98px) {
  .mode-card-container {
    width: 50%;
    margin: 0px 15px !important;
  }
}

@media screen and (max-width: 762px) {
  .flex-wrapper {
    flex-direction: column;
  }

  .mode-training-doubts p {
    font-size: 0.9em;
  }

  .mode-card-container {
    width: 100%;
    margin: 10px 0px !important;
  }
}
