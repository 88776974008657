/* Remove below .temp while developing */
.course-content-section {
  padding: 30px 0px;
}

.angle-down-icon {
  float: right;
  padding-right: 5px;
  color: rgb(167, 164, 164);
  font-size: 1.2em;
  cursor: pointer;
}

.angle-up-icon {
  float: right;
  padding-right: 5px;
  color: rgb(167, 164, 164);
  font-size: 1.2em;
  cursor: pointer;
}
[aria-expanded="true"] .angle-down-icon {
  display: none;
}
[aria-expanded="false"] .angle-up-icon {
  display: none;
}

.toDisplayinline {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}

@media screen and (max-width: 400px) {
  .toDisplayinline {
    justify-content: none;
  }
}
.session-title {
  font-size: 17.5px;
  font-weight: 600;
  justify-content: center;
  align-self: center;
}
.arrow-icons {
  justify-content: center;
  align-self: center;
  padding-right: 5px;
}
.course-content-card {
  background-color: rgb(248, 248, 248);
  padding: 10px;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 6px;
  border: 1px solid rgb(243, 243, 243);
}

.sub-header-container,
.child-header-container {
  border-left: 1px solid rgb(212, 211, 211);
  padding-bottom: 12px;
  padding-left: 30px;
  padding-top: 12px;
  margin-left: 15px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #545151;
}
.child-header-container {
  list-style-type: none;
  padding-left: 25px;
}
.sub-header {
  padding: 5px;
  color: black;
  background: rgb(255, 255, 255);
  display: block;
  margin-left: 5px;
}

.expand-all {
  display: inline-block;
  /* padding: 5px; */
  text-align: end;
  border: none;
  margin: 0;
  background: transparent;
  color: rgb(0, 174, 255);
}

.expand-all:hover,
.expand-all:focus {
  outline: none;
  cursor: pointer;
}
