.App {
  position: relative;
}

.header {
  height: 60px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 8px rgb(194, 194, 194);
  position: fixed;
  width: 100%;
  z-index: 1090;
}

main {
  position: absolute;
  width: 100%;
  /* top: 74px; */
  top: 60px;

  background-color: rgb(255, 255, 255);
}
