@font-face {
  font-family: "Quicksand";
  src: url("../../assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf")
    format("truetype");
}
.testimonials {
  background: linear-gradient(
    45deg,
    rgb(245, 89, 113),
    rgb(243, 112, 167),
    rgb(240, 142, 239)
  );
  position: relative;
  width: 100%;
}

.quote-left {
  padding: 15px;
  box-sizing: content-box;
  color: rgba(255, 255, 255, 0.302);
  width: 100%;
}

.testimonials-header {
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

#testimonials .carousel-indicators > li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

#testimonials .carousel-item {
  color: rgb(255, 255, 255);
  padding: 2% 13% 5%;
}

.testimonial-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.testimonial-content {
  flex: 1 1 auto;
  align-self: center;
  padding: 0px 45px;
  width: 100%;
}

.feedback-title {
  padding-left: 30px;
  width: 100%;
}

.client-picture-section {
  margin: 0px 15px;
  flex: 0 0 150px;
  align-self: center;
}

.client-picture-section .client-profile-rounded {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #efefef;
  color: black;
  position: relative;
}

.client-picture-section .client-shortname {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 40px;
  font-family: monospace;
  color: #ff1b69;
}

.client-picture > img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 1px 4px 14px 0px rgb(86, 86, 86);
  background: transparent;
}

.client-feedback {
  font-size: 1.5em;
  font-family: "Quicksand", sans-serif;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
  width: 100%;
}

.client-designation {
  color: rgb(245, 245, 245);
  letter-spacing: 0.7px;
  width: 100%;
}

/* Media query styles */
@media screen and (max-width: 1200px) {
  .testimonial-container {
    flex-direction: column;
    width: 100%;
  }

  .client-picture {
    flex: unset;
    width: 100px;
  }

  .testimonial-content {
    text-align: center;
    padding: 40px;
  }
}

@media screen and (max-width: 991.98px) {
  .testimonials-header {
    margin-bottom: 15px;
  }

  .feedback-title {
    padding-left: 0px;
  }

  .testimonials-header,
  .client-name,
  .feedback-title {
    font-size: 1.5em;
  }

  .client-picture-section {
    display: none;
  }

  .client-feedback {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 576.98px) {
  .testimonial-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .client-feedback {
    padding: 10px;
    font-size: 15px;
  }

  .testimonials-header {
    font-size: 19px;
  }

  #testimonials .carousel-control-prev > svg,
  #testimonials .carousel-control-next > svg {
    font-size: 25px;
    width: 10%;
  }

  .client-name,
  .feedback-title {
    font-size: 17px;
  }

  #testimonials .carousel-item {
    padding: 2%;
  }
}
