.form-fields {
  /* display: -ms-flexbox; */
  display: flex;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.form-area {
  width: 100%;
  padding: 7px;
  border: 1px solid rgb(202 202 202);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.form-fields textarea {
  width: 100%;
  height: 50px;
  max-height: 60px;
  min-height: 20px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.modal-form label {
  font-size: 16px;
  font-weight: 500;
  color: rgb(44, 41, 41);
}

.modal-form .form-area,
.modal-form .react-tel-input .form-control {
  background-color: transparent;
  color: rgb(46, 43, 43);
}

.modal-form .form-area::placeholder,
.select-course-input::placeholder {
  color: rgb(97, 94, 94);
  font-style: italic;
}

.modal-form .modal-button {
  /* background-color: rgb(255, 130, 92);*/
  background-color: #1b1a1a;
  box-shadow: none;
}

.modal-button {
  float: right;
  background-color: rgb(16, 108, 200);
  border: none;
  color: rgb(255, 255, 255);
  display: block;
  padding: 7px 20px;
  font-weight: 500;
  box-shadow: 2px 3px 8px rgb(184, 184, 184);
  outline: none;
}

.modal-button:focus {
  outline: none;
}

.modal-button:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

.modal-form .modal-button:hover {
  box-shadow: none;
  background-color: black;
}

.modalIcon {
  padding-left: 10px;
}

.modalIcon .pending-req-icon {
  animation: spin 1s infinite ease;
  -webkit-animation: spin 1s infinite ease;
  -moz-animation: spin 1s infinite ease;
  -o-animation: spin 1s infinite ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

option {
  font-style: normal;
  font-size: 16px;
}

@media screen and (min-width: 990px) {
  .upcomingBatches {
    margin-left: 100px;
    width: 350px;
    max-width: 500px;
    font-size: 15px;
  }
}

.modal-form select {
  background-color: rgb(250, 250, 250);
}

.check-box-upcoming {
  border: none;
  width: 100%;
}

@media (max-width: 490px) {
  .upcomingBatches {
    margin-top: 10px;
    /* width: 250px; */
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
    font-size: 15px;
  }

  .message {
    padding-top: 13%;
  }
}

.query-form label {
  font-weight: 500;
}

.query-send {
  float: right;
  background: linear-gradient(25deg, rgb(240, 142, 239), rgb(245, 89, 113));
  border: none;
  color: rgb(255, 255, 255);
  display: block;
  padding: 7px 14px;
  font-weight: 500;
}

.query-send:focus {
  outline: none;
}

.query-send:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

.query-sendIcon {
  padding-left: 10px;
}

.react-tel-input:focus,
.form-control:focus {
  outline: 0px;
  box-shadow: none;
}

.query-form .react-tel-input {
  border-radius: 4px;
}

.react-tel-input .form-control {
  padding: 10px;
  padding-left: 48px;
  height: 40px;
}

.modal-form .react-tel-input .flag-dropdown {
  background-color: transparent;
}

.select-course-search {
  position: relative;
  width: 100%;
  display: inline-block;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding-bottom: 0;
  background-color: transparent;
}

.select-course-input {
  border: none;
  padding: 5px;
  width: 95%;
  margin-right: 0px;
  margin-bottom: 0;
  background-color: transparent;
  color: #2e2b2b;
}

.select-course-input::-ms-clear {
  display: none;
}

.search-form-icon {
  box-shadow: none;
  color: #7e7b7b;
  margin-left: 6px;
}

.check-box-upcoming label {
  font-size: 16px;
  font-weight: 500;
}

.select-course-search .suggestions {
  position: absolute;
  z-index: 100000 !important;
  box-shadow: 0px 0px 10px rgb(208, 208, 208);
  margin-bottom: 0px;
  width: 100%;
}

.error-message {
  color: rgb(244, 67, 54);
  margin-top: 0;
  font-size: 13px;
}

@media screen and (max-width: 990px) {
  .select-course-input {
    float: none;
    text-align: left;
    width: 90%;
  }
}

.mandatory-fields {
  margin-left: 3px;
  color: rgb(244, 67, 54);
  font-size: 17px;
}

.custom-modal-header {
  border-bottom: none;
  background: #27c68b;
  justify-content: center;
}
.custom-modal-header .close {
  position: absolute;
  right: 20px;
  font-weight: 700;
  font-size: 35px;
  top: 7px;
  margin: 0;
  padding: 0;
  color: white;
  opacity: 1;
}

@media screen and (max-width: 575px) {
  .modal-body {
    padding: 0.1rem;
  }

  .modal-form label {
    font-size: 16px;
  }
}

@media (min-width: 575px) and (max-width: 765px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0.5rem auto;
  }
}
