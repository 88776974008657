/* Remove below .temp while developing */

.duration-box {
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 20px #cfcece;
  margin: 15px 0px;
  background-color: rgb(248, 248, 248);
}

.man-with-hourglass {
  flex: 0 0 210px;
  width: 210px;
  max-width: 210px;
  height: 100%;
}

.duration-specifier-wrap {
  flex: 1 1 auto;
  text-align: -webkit-center;
  text-align: center;
  align-self: center;
}
.duration-specifier-wrap h3 {
  font-weight: 600;
  color: #8c8787;
  font-size: 25px;
}
.duration-specifier-wrap h2 {
  font-size: 90px;
  color: #4992d94d;
  font-weight: 900;
}

.duration-specifier-wrap small {
  font-size: 30px;
  color: #8c8787;
  font-weight: 800;
}
