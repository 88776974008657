.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  transition: 0.5s all ease;
}

.navbar-brand {
  margin-right: 20px;
}

.nav-sub-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-brand .companyName {
  font-size: 1em;
  line-height: 20px;
}
.companyLogo {
  max-width: 120px;
  height: 50px;
  transform: scale(2.5) translate(16px, 1px);
}
.navigation-list {
  border-color: rgb(255, 255, 255);
  padding: 0px 5px;
  transition: 1s all ease;
}

.modal-form {
  padding: 5px 20px;
}

/* Navlink Styles */
.nav-link-item {
  color: rgb(147 142 142);
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
  display: block;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  position: relative;
  text-decoration: none;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.nav-link-item.active,
.nav-link-item.active:hover {
  /* color: rgb(255 255 255) !important;
  background-image: linear-gradient(120deg, #fe5f75 0%, #fc9842 100%); */

  color: #1272d8;
  background-color: transparent;
  text-decoration: none;
}

.nav-link-item.active:after {
  position: absolute;
  content: ".";
  bottom: 0px;
  width: 100%;
  height: 3px;
  background-color: #1572d8;
  opacity: 1;
}
/* On Hovering of navbar links */
.nav-link-item:not(.active):hover {
  text-decoration: none;
}

.nav-link-item:after,
.nav-link-item:before {
  transition: all 0.5s;
}

.nav-link-item {
  position: relative;
}

.nav-link-item:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #aaa;
  height: 1px;
  border-radius: 3px;
}

.nav-link-item:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}

.nav-link-item:not(.active):hover {
  color: #fff;
  z-index: 1;
}

.nav-link-item:not(.active):hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }

  50% {
    width: 100%;
    height: 1px;
  }

  100% {
    width: 100%;
    height: 100%;
    /* background-color: rgb(160, 160, 160); */
    background-color: #616364;
  }
}

/* Navlink button style */
.live-demo-btn,
.become-tutor-btn {
  display: block;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  box-shadow: 1px 3px 16px rgb(221, 221, 221);
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  outline: none;
}

.live-demo-btn {
  background-color: #0062cc;
  background: linear-gradient(45deg, #0062cc, #1c7ade);
  color: rgb(255, 255, 255);
}

.become-tutor-btn {
  background-color: transparent;
  color: rgb(20 115 216);
  border: 2px solid #1272d8;
  padding: 0.4rem 1rem;
}

.live-demo-btn:hover,
.become-tutor-btn:hover {
  box-shadow: 1px 5px 20px rgb(165, 165, 165);
}

.live-demo-btn:focus,
.become-tutor-btn:focus {
  outline: none;
}

/*To eliminate Toggle button border */
.navigation-toggle-button:focus,
.navigation-toggle-button:hover {
  outline: none;
  border: none;
}

.main-navigation .navigation-toggle-button {
  border: none;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 150, 243);
  font-weight: 600;
  transition: 0.5s all ease-in-out;
}

.main-navigation .navigation-toggle-button:hover {
  color: rgb(1, 117, 194);
}

/* Preview Icon Adjustment */
.DemoIcon {
  margin-left: 10px;
  vertical-align: text-top;
  padding: 2px;
  vertical-align: top;
}

/* NavbarSearchBarStyle  */
.navSearchBar {
  position: relative;
  padding: 3px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  font-size: 15px;
  border-radius: 30px;
  -webkit-transition: 1s all ease-in-out;
  -moz-transition: 1s all ease-in-out;
  -o-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
  background-color: rgb(255, 255, 255);
}

.navSearchBar.focused {
  position: absolute !important;
  width: 50% !important;
  max-width: 600px;
  z-index: 99 !important;
}

.search-input-container {
  flex: 1 1 auto;
}

.all-search-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgb(150, 150, 150);
  font-stretch: expanded;
}

.bg-overlay {
  position: fixed;
  background: rgba(21, 20, 20, 0.702);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 65px;
  z-index: 2000;
}

.suggestions-list {
  list-style: none;
  padding: 10px;
  overflow-y: auto;
  box-shadow: 0px 9px 12px rgb(124, 124, 124);
  margin-bottom: 0px;
  border-radius: 5px;
  background-color: rgb(253, 253, 253);
  z-index: 2001;
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0px;
  right: 0px;
}

.suggestions-list li {
  font-size: 15px;
  text-align: left;
  transition: 0.3s all;
  padding: 10px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.suggestion.active,
.suggestions-list li:not(.no-suggestions):hover,
.suggestions-list li:focus {
  background-color: rgb(243, 243, 243);
  color: rgb(0, 112, 251);
  cursor: pointer;
}

.no-suggestions {
  pointer-events: none;
  margin-top: 9px;
  color: rgb(153, 153, 153);
  padding: 0.5rem;
}

/* SearchIcon adjustment */
.SearchIcon {
  margin-left: 10px;
}

.search-responsive-button {
  position: relative;
  top: 2px;
}

/* Logo size */
.Logo {
  width: 60px;
  height: 40px;
}

/* To adjust the input inside the searchbar  */
.all-search-input {
  border: none;
  padding: 8px;
  width: 90%;
  font-size: 17px;
}

.search-courses-menu {
  width: calc(100% - 50px);
  border: none;
  padding: 15px;
  font-weight: 500;
  font-style: 16px;
}

.search-courses-menu::placeholder {
  font-style: italic;
  color: rgb(177, 177, 177);
}

.search-courses-menu::-ms-clear {
  display: none;
}

.all-search-input::-ms-clear {
  display: none;
}

.all-search-input:focus {
  outline: none;
}

/* small screen collapsible nav bar styles */
.navbar-collapse-sm {
  display: none;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 73px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgb(66 65 65 / 98%);
  transition: 0.4s all ease;
  z-index: 999999;
}

.navbar-collapse-sm.show {
  display: block;
  opacity: 1;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.navbar-collapse-sm ul.navbar-nav {
  justify-content: center;

  margin: auto 0px;
  height: 90%;
}

/* responsive serachbar  */

.closebtn {
  padding: 5px;
  background-color: transparent;
  color: rgb(183, 183, 183);
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 7px;
}

.closedemobtn {
  padding: 5px;
  background-color: white;
  color: rgb(183, 183, 183);
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0px;
  z-index: 2001;
}

.closebtn:focus {
  outline: none;
}

/* The overlay effect */
.overlay {
  display: inline-block;
  min-height: 74px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
}

/* Close button */
.overlay .closebtn {
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(128, 128, 128);
}

.overlay button:hover {
  background: rgb(187, 187, 187);
}

.search-responsive {
  margin-top: 0px;
  padding: 0.5rem;
  cursor: pointer;
}

.query-modal .modal-content {
  background-color: #ffffff;
  /* background-image: linear-gradient(315deg, #380036 0%, #0c2cba 74%); */
}

.modal-popup-title {
  position: relative;
  padding: 0px;
  margin: 0px;
  font-size: 21px;
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
  font-weight: 600;
  color: white;
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-moz-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-o-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

/* modal form end */

/* Responsive searchbar  */
@media (max-width: 575px) {
  .Logo {
    width: 40px;
  }

  .main-navigation .navigation-toggle-button {
    font-size: 16px;
    padding: 0px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 762px) {
  .navbar-brand .companyName {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 800px) {
  .navSearchBar {
    float: none;
    text-align: left;
    width: 55%;
    margin: 0;
    margin-left: 80px;
    padding: 6px;
  }

  .all-search-input {
    float: none;
    text-align: left;
    width: 90%;
    margin: 0;
    padding: 7px;
  }
}

/* @media screen and (max-width: 991.98px) { */
@media screen and (max-width: 1050.98px) {
  .navigation-list {
    padding: 8px 15px;
  }

  .nav-sub-wrap {
    width: auto;
  }

  .navbar-collapse-sm.show .nav-link-item {
    font-size: 2em;
    padding: 5px 10px;
  }

  .navbar-collapse-sm.show .nav-link-item:hover {
    background-color: rgba(90, 90, 90, 0.815);
  }

  .navbar-collapse-sm.show .nav-link-item.active {
    color: #fff !important;
  }

  .navbar-collapse-sm.show .nav-link-item.active:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  /* .navbar-collapse-sm.show .live-demo-btn,
  .navbar-collapse-sm.show .become-tutor-btn {
    font-size: 1.5em;
    box-shadow: 5px 8px 10px #484848;
  } */
  .navSearchBar {
    display: none;
  }

  .navbar-brand {
    margin: 0px;
  }

  .overlay input[type="text"] {
    border: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 17px;
    padding: 6px 15px;
  }

  .searchIcon {
    margin-left: 20px;
  }

  .overlay input:focus {
    outline: none;
  }

  .overlay input::placeholder {
    font-style: italic;
  }
}

@media screen and (max-width: 1200px) {
  .navSearchBar {
    width: 25%;
  }

  /* To highlight the navbar link while hovering during toggle  */
  .nav-link-item:hover {
    margin: 0 0px;
    border-radius: 0px;
    border-bottom: none;
  }
}

@media (min-width: 1050.98px) {
  .navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
  }
}

@media (min-width: 992px) and (max-width: 1050.98px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}

@media screen and (max-width: 1050.98px) {
  .live-demo-btn {
    font-size: 16px;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 767.98px) {
  .companyLogo {
    transform: scale(1.8) translate(8px, 1px);
  }
}
