/* Remove below .temp while developing */
.temp-upcoming-events {
    font-size: 1.2em;
    padding: 30px 0px;
    border: 1px solid rgb(255, 0, 0);
    min-height: 350px;
}

.upcoming-batch-container {
    height: 150px;
}

article.event-card {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 8px rgb(221, 221, 221);
    border-radius: 5px;
    cursor: pointer;
    margin: 15px;
    padding: 15px;
    transition: 0.4s cubic-bezier(.86, 0, .07, 1);
    min-width: 225px;
    position: relative;
}

article.event-card:hover {
    box-shadow: 1px 6px 10px rgb(197, 197, 197);
}

article.event-card .rounded-event-time {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgba(243, 75, 75, 0.815);
    margin: 0px 5px;
    position: relative;
}

article.event-card .event-date {
    font-weight: 600;
    font-size: 1.6em;
    color: rgb(255, 255, 255);
    display: block;
    position: absolute;
    top: -1px;
    left: 15px;
}

article.event-card .event-month {
    font-weight: 500;
    font-size: 1em;
    color: rgb(248, 248, 248);
    display: block;
    position: absolute;
    top: 29px;
    left: 16px;
}

article.event-card .event-time {
    flex: 1 1 auto;
    color: rgb(109, 109, 109);
    font-weight: 500;
    font-size: 1.2em;
    align-self: center;
    min-width: 100px;
    padding-left: 10px;
}

.book-for-events {
    display: flex;
    align-items: baseline;
    padding: 15px 30px;
    background-color: #fafafa;
    width: 100%;
}

.book-for-events p {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0px;
    color: rgb(99, 99, 99);
    letter-spacing: 0.5px;
}

.book-for-events .book-events-btn {
    background-color: rgb(65, 105, 225);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 7px 15px;
    font-size: 1.2em;

    font-weight: 600;
    margin: 15px;
    box-shadow: 4px 4px 0px rgb(189, 189, 189);
    transform: translate(-3px, -4px);
    transition: 0.4s cubic-bezier(.86, 0, .07, 1);
}

.book-for-events .book-events-btn:hover {
    box-shadow: 0px 0px 8px rgb(160, 160, 160);
    transform: translate(0px, 0px);
}

/* Events discount badge styles */
.event-discount-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    border-top: 40px solid transparent;
    border-left: 65px solid transparent;
    border-bottom: 50px solid rgba(245, 161, 26, 0.96);
    background-color: transparent;
    animation: superNova 5s infinite ease-in-out;


}

@keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgba(255, 174, 0, 0.966);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}

@-moz-keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgb(218, 135, 11);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}

@-o-keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgb(218, 135, 11);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}

@-webkit-keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgb(218, 135, 11);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}


#course-event-discount {
    position: absolute;
    left: -35px;
    top: 23px;
    margin: 0px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}


.upcoming-course-wrapper {
    position: relative;
    height: 200px;
    margin: 1rem 0;
    width: 95%;
}

.upcoming-course-container {
    height: 100%;
    position: absolute;
    width: 100%;
    padding: 0 1em;
}



/* caruosel styles */
.next-event-btn,
.prev-event-btn {
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    z-index: 99;
    box-shadow: 0px 3px 11px rgb(144, 144, 144);
    position: absolute;
    top: calc(50% - 30px);
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
    -moz-transition: 0.5s all ease-in-out;
    -o-transition: 0.5s all ease-in-out;

}

.next-event-btn {
    right: -45px !important;
}

.prev-event-btn {
    left: -40px !important;
}

.next-event-btn:hover,
.prev-event-btn:hover {
    border: 1px solid rgb(216, 216, 216);
    box-shadow: 1px 1px 10px rgb(221, 221, 221);
}

.next-event-btn:hover svg,
.prev-event-btn:hover svg {
    color: rgb(124, 124, 124) !important;

}

.prev-event-btn>svg,
.next-event-btn>svg {
    color: rgb(37, 122, 207);
    font-size: 22px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.next-event-btn>svg {
    left: 13px;
}

/*  */

@media screen and (max-width: 572px) {
    article.event-card {
        margin: 10px 5px;
        flex: 1 1 25%;
    }

    .book-for-events p {
        font-size: 0.9em;
    }
}