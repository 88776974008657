* {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  outline: none;
}
button:focus {
  outline: none;
}
html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (prefers-reduced-motion: reduce) {
  html,
  * {
    scroll-behavior: auto;
  }
}

.App {
  position: relative;
}

.header {
  height: 60px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 8px rgb(194, 194, 194);
  position: fixed;
  width: 100%;
  z-index: 1090;
}

main {
  position: absolute;
  width: 100%;
  /* top: 74px; */
  top: 60px;

  background-color: rgb(255, 255, 255);
}

.form-fields {
  /* display: -ms-flexbox; */
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 10px;
}

.form-area {
  width: 100%;
  padding: 7px;
  border: 1px solid rgb(202 202 202);
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.form-fields textarea {
  width: 100%;
  height: 50px;
  max-height: 60px;
  min-height: 20px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.modal-form label {
  font-size: 16px;
  font-weight: 500;
  color: rgb(44, 41, 41);
}

.modal-form .form-area,
.modal-form .react-tel-input .form-control {
  background-color: transparent;
  color: rgb(46, 43, 43);
}

.modal-form .form-area::-webkit-input-placeholder, .select-course-input::-webkit-input-placeholder {
  color: rgb(97, 94, 94);
  font-style: italic;
}

.modal-form .form-area:-ms-input-placeholder, .select-course-input:-ms-input-placeholder {
  color: rgb(97, 94, 94);
  font-style: italic;
}

.modal-form .form-area::-ms-input-placeholder, .select-course-input::-ms-input-placeholder {
  color: rgb(97, 94, 94);
  font-style: italic;
}

.modal-form .form-area::placeholder,
.select-course-input::placeholder {
  color: rgb(97, 94, 94);
  font-style: italic;
}

.modal-form .modal-button {
  /* background-color: rgb(255, 130, 92);*/
  background-color: #1b1a1a;
  box-shadow: none;
}

.modal-button {
  float: right;
  background-color: rgb(16, 108, 200);
  border: none;
  color: rgb(255, 255, 255);
  display: block;
  padding: 7px 20px;
  font-weight: 500;
  box-shadow: 2px 3px 8px rgb(184, 184, 184);
  outline: none;
}

.modal-button:focus {
  outline: none;
}

.modal-button:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

.modal-form .modal-button:hover {
  box-shadow: none;
  background-color: black;
}

.modalIcon {
  padding-left: 10px;
}

.modalIcon .pending-req-icon {
  animation: spin 1s infinite ease;
  -webkit-animation: spin 1s infinite ease;
  -moz-animation: spin 1s infinite ease;
  -o-animation: spin 1s infinite ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

option {
  font-style: normal;
  font-size: 16px;
}

@media screen and (min-width: 990px) {
  .upcomingBatches {
    margin-left: 100px;
    width: 350px;
    max-width: 500px;
    font-size: 15px;
  }
}

.modal-form select {
  background-color: rgb(250, 250, 250);
}

.check-box-upcoming {
  border: none;
  width: 100%;
}

@media (max-width: 490px) {
  .upcomingBatches {
    margin-top: 10px;
    /* width: 250px; */
    width: 100%;
    max-width: 100%;
    padding-left: 0px;
    font-size: 15px;
  }

  .message {
    padding-top: 13%;
  }
}

.query-form label {
  font-weight: 500;
}

.query-send {
  float: right;
  background: linear-gradient(25deg, rgb(240, 142, 239), rgb(245, 89, 113));
  border: none;
  color: rgb(255, 255, 255);
  display: block;
  padding: 7px 14px;
  font-weight: 500;
}

.query-send:focus {
  outline: none;
}

.query-send:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

.query-sendIcon {
  padding-left: 10px;
}

.react-tel-input:focus,
.form-control:focus {
  outline: 0px;
  box-shadow: none;
}

.query-form .react-tel-input {
  border-radius: 4px;
}

.react-tel-input .form-control {
  padding: 10px;
  padding-left: 48px;
  height: 40px;
}

.modal-form .react-tel-input .flag-dropdown {
  background-color: transparent;
}

.select-course-search {
  position: relative;
  width: 100%;
  display: inline-block;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding-bottom: 0;
  background-color: transparent;
}

.select-course-input {
  border: none;
  padding: 5px;
  width: 95%;
  margin-right: 0px;
  margin-bottom: 0;
  background-color: transparent;
  color: #2e2b2b;
}

.select-course-input::-ms-clear {
  display: none;
}

.search-form-icon {
  box-shadow: none;
  color: #7e7b7b;
  margin-left: 6px;
}

.check-box-upcoming label {
  font-size: 16px;
  font-weight: 500;
}

.select-course-search .suggestions {
  position: absolute;
  z-index: 100000 !important;
  box-shadow: 0px 0px 10px rgb(208, 208, 208);
  margin-bottom: 0px;
  width: 100%;
}

.error-message {
  color: rgb(244, 67, 54);
  margin-top: 0;
  font-size: 13px;
}

@media screen and (max-width: 990px) {
  .select-course-input {
    float: none;
    text-align: left;
    width: 90%;
  }
}

.mandatory-fields {
  margin-left: 3px;
  color: rgb(244, 67, 54);
  font-size: 17px;
}

.custom-modal-header {
  border-bottom: none;
  background: #27c68b;
  justify-content: center;
}
.custom-modal-header .close {
  position: absolute;
  right: 20px;
  font-weight: 700;
  font-size: 35px;
  top: 7px;
  margin: 0;
  padding: 0;
  color: white;
  opacity: 1;
}

@media screen and (max-width: 575px) {
  .modal-body {
    padding: 0.1rem;
  }

  .modal-form label {
    font-size: 16px;
  }
}

@media (min-width: 575px) and (max-width: 765px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0.5rem auto;
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  transition: 0.5s all ease;
}

.navbar-brand {
  margin-right: 20px;
}

.nav-sub-wrap {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-brand .companyName {
  font-size: 1em;
  line-height: 20px;
}
.companyLogo {
  max-width: 120px;
  height: 50px;
  transform: scale(2.5) translate(16px, 1px);
}
.navigation-list {
  border-color: rgb(255, 255, 255);
  padding: 0px 5px;
  transition: 1s all ease;
}

.modal-form {
  padding: 5px 20px;
}

/* Navlink Styles */
.nav-link-item {
  color: rgb(147 142 142);
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
  display: block;
  padding: 0.5rem 0.75rem;
  border-radius: 3px;
  position: relative;
  text-decoration: none;
  transition: 0.5s all ease;
}

.nav-link-item.active,
.nav-link-item.active:hover {
  /* color: rgb(255 255 255) !important;
  background-image: linear-gradient(120deg, #fe5f75 0%, #fc9842 100%); */

  color: #1272d8;
  background-color: transparent;
  text-decoration: none;
}

.nav-link-item.active:after {
  position: absolute;
  content: ".";
  bottom: 0px;
  width: 100%;
  height: 3px;
  background-color: #1572d8;
  opacity: 1;
}
/* On Hovering of navbar links */
.nav-link-item:not(.active):hover {
  text-decoration: none;
}

.nav-link-item:after,
.nav-link-item:before {
  transition: all 0.5s;
}

.nav-link-item {
  position: relative;
}

.nav-link-item:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #aaa;
  height: 1px;
  border-radius: 3px;
}

.nav-link-item:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}

.nav-link-item:not(.active):hover {
  color: #fff;
  z-index: 1;
}

.nav-link-item:not(.active):hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

@-webkit-keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }

  50% {
    width: 100%;
    height: 1px;
  }

  100% {
    width: 100%;
    height: 100%;
    /* background-color: rgb(160, 160, 160); */
    background-color: #616364;
  }
}

@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }

  50% {
    width: 100%;
    height: 1px;
  }

  100% {
    width: 100%;
    height: 100%;
    /* background-color: rgb(160, 160, 160); */
    background-color: #616364;
  }
}

/* Navlink button style */
.live-demo-btn,
.become-tutor-btn {
  display: block;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  box-shadow: 1px 3px 16px rgb(221, 221, 221);
  transition: 0.3s all;
  outline: none;
}

.live-demo-btn {
  background-color: #0062cc;
  background: linear-gradient(45deg, #0062cc, #1c7ade);
  color: rgb(255, 255, 255);
}

.become-tutor-btn {
  background-color: transparent;
  color: rgb(20 115 216);
  border: 2px solid #1272d8;
  padding: 0.4rem 1rem;
}

.live-demo-btn:hover,
.become-tutor-btn:hover {
  box-shadow: 1px 5px 20px rgb(165, 165, 165);
}

.live-demo-btn:focus,
.become-tutor-btn:focus {
  outline: none;
}

/*To eliminate Toggle button border */
.navigation-toggle-button:focus,
.navigation-toggle-button:hover {
  outline: none;
  border: none;
}

.main-navigation .navigation-toggle-button {
  border: none;
  background-color: rgb(255, 255, 255);
  color: rgb(33, 150, 243);
  font-weight: 600;
  transition: 0.5s all ease-in-out;
}

.main-navigation .navigation-toggle-button:hover {
  color: rgb(1, 117, 194);
}

/* Preview Icon Adjustment */
.DemoIcon {
  margin-left: 10px;
  vertical-align: text-top;
  padding: 2px;
  vertical-align: top;
}

/* NavbarSearchBarStyle  */
.navSearchBar {
  position: relative;
  padding: 3px;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  font-size: 15px;
  border-radius: 30px;
  transition: 1s all ease-in-out;
  background-color: rgb(255, 255, 255);
}

.navSearchBar.focused {
  position: absolute !important;
  width: 50% !important;
  max-width: 600px;
  z-index: 99 !important;
}

.search-input-container {
  flex: 1 1 auto;
}

.all-search-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgb(150, 150, 150);
  font-stretch: expanded;
}

.all-search-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgb(150, 150, 150);
  font-stretch: expanded;
}

.all-search-input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgb(150, 150, 150);
  font-stretch: expanded;
}

.all-search-input::placeholder {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;

  color: rgb(150, 150, 150);
  font-stretch: expanded;
}

.bg-overlay {
  position: fixed;
  background: rgba(21, 20, 20, 0.702);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 65px;
  z-index: 2000;
}

.suggestions-list {
  list-style: none;
  padding: 10px;
  overflow-y: auto;
  box-shadow: 0px 9px 12px rgb(124, 124, 124);
  margin-bottom: 0px;
  border-radius: 5px;
  background-color: rgb(253, 253, 253);
  z-index: 2001;
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0px;
  right: 0px;
}

.suggestions-list li {
  font-size: 15px;
  text-align: left;
  transition: 0.3s all;
  padding: 10px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.suggestion.active,
.suggestions-list li:not(.no-suggestions):hover,
.suggestions-list li:focus {
  background-color: rgb(243, 243, 243);
  color: rgb(0, 112, 251);
  cursor: pointer;
}

.no-suggestions {
  pointer-events: none;
  margin-top: 9px;
  color: rgb(153, 153, 153);
  padding: 0.5rem;
}

/* SearchIcon adjustment */
.SearchIcon {
  margin-left: 10px;
}

.search-responsive-button {
  position: relative;
  top: 2px;
}

/* Logo size */
.Logo {
  width: 60px;
  height: 40px;
}

/* To adjust the input inside the searchbar  */
.all-search-input {
  border: none;
  padding: 8px;
  width: 90%;
  font-size: 17px;
}

.search-courses-menu {
  width: calc(100% - 50px);
  border: none;
  padding: 15px;
  font-weight: 500;
  font-style: 16px;
}

.search-courses-menu::-webkit-input-placeholder {
  font-style: italic;
  color: rgb(177, 177, 177);
}

.search-courses-menu:-ms-input-placeholder {
  font-style: italic;
  color: rgb(177, 177, 177);
}

.search-courses-menu::-ms-input-placeholder {
  font-style: italic;
  color: rgb(177, 177, 177);
}

.search-courses-menu::placeholder {
  font-style: italic;
  color: rgb(177, 177, 177);
}

.search-courses-menu::-ms-clear {
  display: none;
}

.all-search-input::-ms-clear {
  display: none;
}

.all-search-input:focus {
  outline: none;
}

/* small screen collapsible nav bar styles */
.navbar-collapse-sm {
  display: none;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 73px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgb(66 65 65 / 98%);
  transition: 0.4s all ease;
  z-index: 999999;
}

.navbar-collapse-sm.show {
  display: block;
  opacity: 1;
  -webkit-animation: fadeIn 1s ease;
          animation: fadeIn 1s ease;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.navbar-collapse-sm ul.navbar-nav {
  justify-content: center;

  margin: auto 0px;
  height: 90%;
}

/* responsive serachbar  */

.closebtn {
  padding: 5px;
  background-color: transparent;
  color: rgb(183, 183, 183);
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 7px;
}

.closedemobtn {
  padding: 5px;
  background-color: white;
  color: rgb(183, 183, 183);
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 0px;
  z-index: 2001;
}

.closebtn:focus {
  outline: none;
}

/* The overlay effect */
.overlay {
  display: inline-block;
  min-height: 74px;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
}

/* Close button */
.overlay .closebtn {
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(128, 128, 128);
}

.overlay button:hover {
  background: rgb(187, 187, 187);
}

.search-responsive {
  margin-top: 0px;
  padding: 0.5rem;
  cursor: pointer;
}

.query-modal .modal-content {
  background-color: #ffffff;
  /* background-image: linear-gradient(315deg, #380036 0%, #0c2cba 74%); */
}

.modal-popup-title {
  position: relative;
  padding: 0px;
  margin: 0px;
  font-size: 21px;
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
  font-weight: 600;
  color: white;
}

@-webkit-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

/* modal form end */

/* Responsive searchbar  */
@media (max-width: 575px) {
  .Logo {
    width: 40px;
  }

  .main-navigation .navigation-toggle-button {
    font-size: 16px;
    padding: 0px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 762px) {
  .navbar-brand .companyName {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 800px) {
  .navSearchBar {
    float: none;
    text-align: left;
    width: 55%;
    margin: 0;
    margin-left: 80px;
    padding: 6px;
  }

  .all-search-input {
    float: none;
    text-align: left;
    width: 90%;
    margin: 0;
    padding: 7px;
  }
}

/* @media screen and (max-width: 991.98px) { */
@media screen and (max-width: 1050.98px) {
  .navigation-list {
    padding: 8px 15px;
  }

  .nav-sub-wrap {
    width: auto;
  }

  .navbar-collapse-sm.show .nav-link-item {
    font-size: 2em;
    padding: 5px 10px;
  }

  .navbar-collapse-sm.show .nav-link-item:hover {
    background-color: rgba(90, 90, 90, 0.815);
  }

  .navbar-collapse-sm.show .nav-link-item.active {
    color: #fff !important;
  }

  .navbar-collapse-sm.show .nav-link-item.active:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  /* .navbar-collapse-sm.show .live-demo-btn,
  .navbar-collapse-sm.show .become-tutor-btn {
    font-size: 1.5em;
    box-shadow: 5px 8px 10px #484848;
  } */
  .navSearchBar {
    display: none;
  }

  .navbar-brand {
    margin: 0px;
  }

  .overlay input[type="text"] {
    border: none;
    border-bottom: 1px solid #e2e2e2;
    font-size: 17px;
    padding: 6px 15px;
  }

  .searchIcon {
    margin-left: 20px;
  }

  .overlay input:focus {
    outline: none;
  }

  .overlay input::-webkit-input-placeholder {
    font-style: italic;
  }

  .overlay input:-ms-input-placeholder {
    font-style: italic;
  }

  .overlay input::-ms-input-placeholder {
    font-style: italic;
  }

  .overlay input::placeholder {
    font-style: italic;
  }
}

@media screen and (max-width: 1200px) {
  .navSearchBar {
    width: 25%;
  }

  /* To highlight the navbar link while hovering during toggle  */
  .nav-link-item:hover {
    margin: 0 0px;
    border-radius: 0px;
    border-bottom: none;
  }
}

@media (min-width: 1050.98px) {
  .navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
  }
}

@media (min-width: 992px) and (max-width: 1050.98px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .navbar-expand-lg .navbar-toggler {
    display: block;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
}

@media screen and (max-width: 1050.98px) {
  .live-demo-btn {
    font-size: 16px;
    padding: 0.5rem;
  }
}

@media screen and (max-width: 767.98px) {
  .companyLogo {
    transform: scale(1.8) translate(8px, 1px);
  }
}

.private-policy-title,
.terms-policy-title {
  position: relative;
  font-size: 30px;
  font-weight: 600;
  padding: 3% 1%;
}

.terms-conditions-title,
.privacy-title {
  font-size: 27px;
  font-weight: 600;
  position: relative;
}

.terms-conditions-title::after,
.privacy-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(32, 147, 241);
  left: 25%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
          animation: expandCenter 1s ease-in-out;
  box-shadow: 0px 7px 15px 0px #b9b9b9;
}

@-webkit-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.policy-titles {
  font-size: 22px;
  font-weight: 600;
}

.sub-content {
  margin-left: 25px;
}

.data-header {
  margin-top: 10px;
}

.data-collected-content {
  margin-left: 5px;
}

.privacy-policy-content,
.terms-conditions-content {
  line-height: 25px;
  text-align: justify;
  font-size: 14px;
  color: #494949;
}

.personal-data-list,
.responsibility-warranty-lists {
  margin-left: 40px;
}

.sub-content-paragragh {
  margin-left: 25px;
}

.email-style {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  margin-left: 35px;
}
.terms-contact-separator {
  padding-left: 10px;
  padding-right: 10px;
}
.terms-email-separator {
  padding-left: 16px;
  padding-right: 10px;
}

.policy-modal ol,
.policy-modal ul,
.policy-modal p {
  font-family: "Montserrat", sans-serif;
}

.close-button {
  display: block;
  margin: 0;
  padding: 7px 20px;
  border: 1px solid transparent;
  background-color: rgb(16, 108, 200);
  color: rgb(255, 255, 255);
  font-weight: 600;
  height: 100%;
  box-shadow: 2px 3px 8px rgb(184, 184, 184);
  transition: 0.3s all;
}

.close-button:hover,
.close-button:focus {
  outline: none;
}

.close-button:hover {
  box-shadow: 1px 5px 8px rgb(167, 167, 167);
}

@media (min-width: 575px) and (max-width: 765px) {
  .modal-dialog {
    max-width: 95%;
    margin: 0.5rem auto;
  }
}

@media (min-width: 765px) and (max-width: 990px) {
  .policy-modal .modal-dialog {
    max-width: 80%;
    margin: 0.75rem auto;
  }
}

.policy-modal .modal-body {
  padding: 10px 40px;
}

@media (max-width: 555px) {
  .policy-modal .modal-body {
    padding: 20px;
  }

  .sub-content {
    margin-left: 20px;
  }

  .personal-data-list {
    margin-left: 25px;
  }

  .responsibility-warranty-lists {
    margin-left: 25px;
  }

  .email-style {
    margin-left: 25px;
  }

  .policy-titles {
    font-size: 20px;
  }

  .terms-policy-title::after {
    width: 240px;
  }

  .private-policy-title::after {
    width: 145px;
  }

  .terms-conditions-title {
    font-size: 25px;
    font-weight: 600;
  }

  .privacy-title {
    font-size: 25px;
    font-weight: 600;
  }
}

/* Main styles */
.foot-section {
  height: auto;
  color: rgb(255, 255, 255);
  background: linear-gradient(to right, rgb(14, 13, 13), rgb(4, 1, 22));
  position: relative;
  padding: 3em;
}
.foot-section .companyLogo {
  transform: scale(3.5) translate(0px, 6px);
  margin-bottom: 15px;
}

.logo-image {
  width: 50px;
  height: auto;
}

.motto {
  flex-wrap: wrap;
  font-size: 13px;
  color: rgb(207, 207, 207);
}

.foot-flex-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.logo-container {
  padding: 0px 15px;
  width: 20%;
}

.foot-nav-container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-evenly;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 0px 15px;
  text-align: left;
}

.foot-nav-container div {
  padding: 0px 10px;
}

/* logo styles */
.logo-img {
  font-size: 20px;
  color: rgb(255, 255, 255);
}

/* Footer header styles */
.foot-sub-header {
  display: inline-block;
  color: rgb(255, 255, 255);
  margin-bottom: 5px;
  position: relative;
}

.foot-sub-header::after {
  content: "";
  width: 100%;
  height: 4px;
  background-color: rgb(232, 139, 0);
  position: absolute;
  top: 30px;
  left: 0px;
  border-radius: 1px;
}

.footer-nav-links {
  list-style-type: none;
  margin: 15px 0px 0px 0px;
  padding: 0px;
}

.button-nav-link {
  border: none;
  background-color: transparent;
  color: rgb(175, 175, 175);
}

.attribute {
  font-size: 11px;
  color: rgb(105, 105, 105);
}

.attribute-link {
  color: rgb(105, 105, 105);
}

.attribute-link:hover {
  text-decoration: none;
  color: rgb(240, 248, 255);
}

.footer-nav-item {
  margin: 0px 5px;
}

.bottom-info {
  display: flex;
  justify-content: flex-end;
  bottom: 0px;
  color: rgb(105, 105, 105);
}

/* Footer list stytles */

.footer-nav-item {
  cursor: pointer;
  position: relative;
  margin-bottom: 8px;
}

.footer-nav-item a,
.footer-nav-item button {
  font-weight: 400;
  position: relative;
  color: rgb(194, 194, 194);
  font-size: 15px;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -webkit-transition: 0.2s all;
}

.button-nav-link:hover {
  text-decoration: underline;
}

.button-nav-link:focus,
.button-nav-link:hover {
  outline: none;
}

.footer-nav-item a:hover,
.footer-nav-item button:hover {
  color: rgb(235, 235, 235);
}

.footer-nav-item.social a {
  text-decoration: none !important;
  display: block;
  margin-bottom: 5px;
}

.next-page-url a:hover {
  color: rgb(235, 235, 235);
  text-decoration: none;
}

/* social-links styles */
.social-links-container .footer-nav-item svg {
  font-size: 20px;
  color: rgb(197, 197, 197);
  transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -webkit-transition: 0.5s all;
}

.social-links-container .footer-nav-item svg:hover {
  color: rgb(238, 146, 9);
}

/* next page url */
.next-page-url {
  font-size: 18px;
}

.next-page-url a {
  color: rgb(207, 207, 207);
  font-size: 16px;
  padding-left: 10px;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  text-decoration: underline;
}

/* copyright info styles */
.copyright-section {
  bottom: 0px;
  padding-left: 10px;
}

.copyright-info {
  font-size: 12px;
  color: rgb(105, 105, 105);
}

@media screen and (max-width: 862px) {
  .foot-section {
    height: auto;
    font-size: 14px;
  }
  .bottom-info {
    justify-content: center;
  }
  .foot-flex-container {
    flex-direction: column;
    padding-bottom: 25px;
  }

  .logo-container {
    align-self: center;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    text-align: -webkit-center;
  }

  .next-page-section {
    align-self: center;
    padding-bottom: 30px;
  }

  .foot-nav-container {
    order: 2;
    padding-bottom: 30px;
  }
  .foot-section .companyLogo {
    transform: scale(3) translate(0px, 6px);
    margin-bottom: 25px;
  }
}

/* @media screen and (max-width:762px) {
  .foot-section {
    height: auto;
    font-size: 14px;
  }

  .foot-flex-container {
    flex-direction: column;
    padding-bottom: 25px;
  }

  .logo-container {
    align-self: center;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    text-align: -webkit-center;
  }

  .next-page-section {
    align-self: center;
    padding-bottom: 30px;
  }

  .foot-nav-container {
    order: 2;
    padding-bottom: 30px;
  }
} */

@media screen and (max-width: 562px) {
  .foot-section {
    text-align: center;
    font-size: 14px;
    padding: 32px 2px;
  }

  .foot-nav-container {
    flex-wrap: nowrap;
    font-size: 12px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-nav-links {
    align-items: center;
    justify-content: center;
  }
  .foot-sub-header::after {
    height: 3px;
    top: 18px;
  }

  .foot-sub-header {
    font-size: 15px;
  }
  .bottom-info {
    flex-direction: column-reverse;
  }
  .attribute {
    margin-bottom: 15px;
  }
  .logo-container {
    width: 100%;
    padding-left: 45px;
    padding-right: 45px;
  }
}

.process-card-container {
    margin: 10px 0px;
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
}

.process-card {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 280px;
    padding: 15px;
    border-radius: 7px;
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: 1px 1px 10px rgb(212, 212, 212);
}

.process-card:hover {
    box-shadow: 4px 7px 20px rgb(207, 207, 207);
}

.process-card .process-step-badge {
    position: absolute;
    left: 12px;
    width: 30px;
    height: 30px;
    margin: 0px;
    border-radius: 50%;
    background-color: rgb(255, 117, 107);
    padding-left: 11px;
    padding-top: 5px;
    color: rgb(255, 255, 255);
    font-size: 0.8em;
    box-shadow: 1px 2px 8px rgb(173, 173, 173);
}

.process-image {
    width: 75px;
    height: auto;
    max-width: 100px;
    padding: 15px 0px;
}

.process-card-title {
    width:100%;
    color: rgb(255, 70, 143);
    font-weight: 700;
    margin-bottom: 15px;
    padding-top: 15px;
    text-align: -webkit-center;
    text-align: center;
}

#a {
    scroll-behavior: smooth;
}

.process-description {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: rgb(131, 131, 131);
    text-align: -webkit-center;
    text-align: center;
    letter-spacing: 0.5px;
}

.process-query-qstn {
    color: rgb(134, 134, 134);
    font-size: 1.2em;
    padding: 10px 20px;
    letter-spacing: 0.7px;

}

.process-query-btn:focus {
    outline: none;
}

.process-query-btn {
    background: linear-gradient(to right, rgb(255, 114, 152), rgb(255, 95, 97));
    background-color: rgb(255, 95, 97);
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding: 7px 20px;
    font-size: 1.2em;
    cursor: pointer;
    letter-spacing: 0.5px;
    box-shadow: 0px 4px 10px rgb(202, 202, 202);
    outline: none;
    transition: 0.3s all;
}

.process-query-btn:hover {
    box-shadow: 3px 8px 10px rgb(202, 202, 202);
}


@media screen and (max-width:576.98px) {
    .process-card-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width:767.98px) {
    .query-model-section {
        flex-direction: column;
    }

    .process-query-qstn {
        margin-bottom: 15px;
    }
}
#home-key-features-title {
  color: rgb(255, 255, 255);
}

.key-features {
  padding: 80px 0px !important;
  position: relative;
  background-image: url(/static/media/keyFeaturesBG.700a2bfe.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.73);
}

.key-features::after {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73);
}

.key-features .rel-container {
  z-index: 9;
  position: relative;
}

.keyfeature-card-container {
  margin: 10px 0px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 350px;
}

.keyfeature-card {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 350px;
  max-width: 350px;
  /* height: 100%; */
  margin: 15px;
  min-height: 280px;
  padding: 15px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  transition: 0.3s all;
  /* box-shadow: 3px 2px 8px rgb(0, 0, 0); */
}

.keyfeature-card:hover .keyfeature-image {
  -webkit-transition: 0.7s all;
  -webkit-transform: scale(1.2);
  -moz-transition: 0.7s all;
  -moz-transform: scale(1.2);
  -o-transition: 0.7s all;
  -o-transform: scale(1.2);
  transition: 0.7s all;
  transform: scale(1.2);
}

.keyfeature-card:hover .keyfeature-description {
  /* box-shadow: 3px 3px 13px rgb(0, 0, 0); */
  color: #fff;
}

.keyfeature-image {
  width: 150px;
  height: auto;
  max-width: 150px;
  padding: 15px 0px;
}

.keyfeature-card-title {
  color: rgb(255, 255, 255);
  text-align: -webkit-center;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 15px;
}

.keyfeature-description {
  font-size: 17px;
  font-weight: 600;
  color: rgb(158, 158, 158);
  text-align: -webkit-center;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  transition: 0.7s all;
}

@media screen and (max-width: 576.98px) {
  .key-features {
    height: auto;
  }

  .key-features .rel-container > .container-fluid {
    width: 100%;
  }

  .keyfeature-card-container {
    padding-left: 0rem;
    padding-right: 0rem;
    height: 300px;
  }

  .keyfeature-card {
    height: auto;
    width: 100%;
    margin: 0px;
  }
}

@media screen and (max-width: 991.98px) {
  .keyfeature-card-container {
    text-align: center;
    text-align: -webkit-center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .key-features .rel-container > .container-fluid {
    width: 75%;
  }
}

/* ************** */

article.course-grid-card {
  width: 300px;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  transition: 0.4s all ease;
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

article.course-grid-card:hover {
  box-shadow: 2px 8px 11px rgb(206, 206, 206);
}

article.course-grid-card .thumbnail {
  text-align: -webkit-center;
  width: auto;
  height: 185px;
  border-radius: 3px;
  padding: 7px;
}

article.course-grid-card .thumbnail > img {
  width: 100%;
  height: 100%;
}

.gray-bg {
  width: 98%;
  height: 100%;
  border-radius: 3px;
  background-color: rgba(226, 226, 226, 0.4);
}

article.course-grid-card .course-details {
  width: auto;
  height: 300px;
  position: relative;
  border-radius: 3px;
  padding: 14px 24px;
  background: rgb(255, 255, 255);
  transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-grid-card .course-details .course-title {
  position: relative;
  margin: 0px;
  color: rgb(21, 37, 54);
  font-size: 1.25rem;
  text-shadow: 0 0 0px rgb(50, 87, 127);
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  orientation: portrait;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

article.course-grid-card .course-details .course-card-rating {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: -2px;
}

article.course-grid-card .course-details .course-card-rating > span {
  position: relative;
  top: 4px;
  margin-right: 10px;
}

article.course-grid-card .course-details .more-info {
  display: none;
  opacity: 0;

  position: relative;
}

article.course-grid-card .course-details .course-summary {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 5;
  orientation: portrait;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  font-size: 17px;
  color: rgba(43, 43, 44, 0.7);
  margin-bottom: 10px;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-grid-card .course-details .view-more-option {
  padding: 7px 14px;
  text-align: center;
  text-align: -webkit-center;
  background-color: rgb(240, 248, 255);
  color: rgb(0, 123, 255);
  font-weight: 600;
  border: 0px;
  box-shadow: 2px 2px 9px rgb(226, 226, 226);
  outline: 0px;
  transition: 0.5s all ease-in-out;
}

article.course-grid-card .course-details .view-more-option:hover {
  box-shadow: 2px 2px 9px rgb(207, 207, 207);
  text-decoration: underline;
}

/* article.course-grid-card:focus-within .course-details,
article.course-grid-card:focus .course-details, */
article.course-grid-card:hover .course-details {
  transform: translateY(-185px);
}

/* article.course-grid-card:focus-within .course-details .more-info,
article.course-grid-card:focus .course-details .more-info, */
article.course-grid-card:hover .course-details .more-info {
  display: block;
  opacity: 1;
}

@media screen and (max-width: 991.98px) {
  article.course-grid-card {
    margin: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  article.course-grid-card:hover .course-details {
    transform: translateY(-170px);
  }

  article.course-grid-card .course-details .course-title {
    font-size: 1em;
  }

  article.course-grid-card .course-details .course-summary {
    padding-right: 0%;
    font-size: 15px;
    line-clamp: 4;
    -webkit-line-clamp: 3;
  }

  article.course-grid-card .course-details {
    padding: 0px 7px;
  }

  article.course-grid-card:hover .course-details {
    padding: 14px 24px;
  }

  article.course-grid-card .thumbnail {
    max-height: 200px;
  }

  article.course-grid-card {
    width: 250px;
    max-height: 270px;
    margin: 6px;
  }
}

/* popular courses styles */

/* Popular course Header */
.pop-title-container {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  justify-content: -webkit-center;
  flex-direction: column;
  margin-right: 1.5em;
  width: 100%;
}

.pop-wrapper {
  margin: 2.5rem 0rem;
  padding: 0rem 2.5rem;
}

.pop-course-container {
  height: 350px;
}

/* Popular course Header styles */
.pop-course-title {
  margin-bottom: 15px;
  line-height: 1;
  color: #1a7adb;
  position: relative;
  font-weight: 700;
  font-size: 2em;
}

.pop-course-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-transition: transform 250ms ease-in-out;
  -moz-transition: transform 250ms ease-in-out;
  -o-transition: transform 250ms ease-in-out;
  animation: expandToRight 1s ease-in-out;
  -webkit-animation: expandToRight 1s ease-in-out;
  -moz-animation: expandToRight 1s ease-in-out;
  -o-animation: expandToRight 1s ease-in-out;
}

@-webkit-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

/*  */
.pop-course-loadmore {
  text-decoration: none;
  width: 170px;
  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(27, 124, 221), rgb(8, 92, 177));
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
  font-weight: 500;
  padding: 7px 20px;
  font-size: 1.2em;
  cursor: pointer;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 10px rgb(202, 202, 202);
  outline: none;
  transition: 0.3s all;
  position: relative;
  left: 10px;
  top: 35px;
}
.pop-course-loadmore:focus {
  outline: none;
}
.pop-course-loadmore:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  box-shadow: 3px 8px 10px rgb(202, 202, 202);
}

.pop-course-info {
  padding: 20px 0px;
  border-radius: 8px;
  color: rgb(105, 105, 105);
  padding-bottom: 30px;
}
.next-course-btn,
.prev-course-btn {
  background-color: rgb(255, 255, 255);
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  z-index: 99;
  box-shadow: 0px 3px 11px rgb(144, 144, 144);
  position: absolute;
  top: calc(50% - 30px);
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  outline: none;
}
.next-course-btn:focus,
.prev-course-btn:focus {
  outline: none;
}
.next-course-btn {
  right: -45px !important;
}

.prev-course-btn {
  left: -40px !important;
}

.next-course-btn:hover,
.prev-course-btn:hover {
  background-color: rgb(33, 150, 243) !important;
  border: 1px solid rgb(169, 169, 169);
  box-shadow: 1px 1px 10px rgb(192, 192, 192);
}

.next-course-btn:hover svg,
.prev-course-btn:hover svg {
  color: rgb(245, 245, 245) !important;
}

.prev-course-btn > svg,
.next-course-btn > svg {
  color: rgb(33, 150, 243);
  font-size: 22px;
  position: absolute;
  top: 8px;
  left: 10px;
}

.next-course-btn > svg {
  left: 13px;
}

/* customizing slick */
.slick-active > div:focus {
  outline: none !important;
}

.slick-list,
.slick-track {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .pop-course-container {
    height: 300px;
  }

  .pop-wrapper {
    margin: 1.5rem 0rem !important;
  }
}

@media screen and (max-width: 991.98px) {
  .pop-wrapper {
    padding: 0rem 2.3rem;
  }

  .pop-title-container {
    text-align: center;
    text-align: -webkit-center;
    align-items: center;
  }

  .pop-course-title {
    text-align: center;
    text-align: -webkit-center;
    font-size: 2rem;
  }

  .pop-course-title::after {
    left: 25%;
    bottom: -20px;
    animation: expandCenter 1s ease-in-out;
    -webkit-animation: expandCenter 1s ease-in-out;
    -moz-animation: expandCenter 1s ease-in-out;
    -o-animation: expandCenter 1s ease-in-out;
  }

  .pop-course-loadmore {
    align-self: center;
  }
}

@font-face {
  font-family: "Quicksand";
  src: url(/static/media/Quicksand-VariableFont_wght.f9baef8a.ttf)
    format("truetype");
}
.testimonials {
  background: linear-gradient(
    45deg,
    rgb(245, 89, 113),
    rgb(243, 112, 167),
    rgb(240, 142, 239)
  );
  position: relative;
  width: 100%;
}

.quote-left {
  padding: 15px;
  box-sizing: content-box;
  color: rgba(255, 255, 255, 0.302);
  width: 100%;
}

.testimonials-header {
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  width: 100%;
}

#testimonials .carousel-indicators > li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

#testimonials .carousel-item {
  color: rgb(255, 255, 255);
  padding: 2% 13% 5%;
}

.testimonial-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.testimonial-content {
  flex: 1 1 auto;
  align-self: center;
  padding: 0px 45px;
  width: 100%;
}

.feedback-title {
  padding-left: 30px;
  width: 100%;
}

.client-picture-section {
  margin: 0px 15px;
  flex: 0 0 150px;
  align-self: center;
}

.client-picture-section .client-profile-rounded {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #efefef;
  color: black;
  position: relative;
}

.client-picture-section .client-shortname {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 40px;
  font-family: monospace;
  color: #ff1b69;
}

.client-picture > img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 1px 4px 14px 0px rgb(86, 86, 86);
  background: transparent;
}

.client-feedback {
  font-size: 1.5em;
  font-family: "Quicksand", sans-serif;
  color: rgb(255, 255, 255);
  letter-spacing: 0.5px;
  width: 100%;
}

.client-designation {
  color: rgb(245, 245, 245);
  letter-spacing: 0.7px;
  width: 100%;
}

/* Media query styles */
@media screen and (max-width: 1200px) {
  .testimonial-container {
    flex-direction: column;
    width: 100%;
  }

  .client-picture {
    flex: unset;
    width: 100px;
  }

  .testimonial-content {
    text-align: center;
    padding: 40px;
  }
}

@media screen and (max-width: 991.98px) {
  .testimonials-header {
    margin-bottom: 15px;
  }

  .feedback-title {
    padding-left: 0px;
  }

  .testimonials-header,
  .client-name,
  .feedback-title {
    font-size: 1.5em;
  }

  .client-picture-section {
    display: none;
  }

  .client-feedback {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 576.98px) {
  .testimonial-content {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
  }

  .client-feedback {
    padding: 10px;
    font-size: 15px;
  }

  .testimonials-header {
    font-size: 19px;
  }

  #testimonials .carousel-control-prev > svg,
  #testimonials .carousel-control-next > svg {
    font-size: 25px;
    width: 10%;
  }

  .client-name,
  .feedback-title {
    font-size: 17px;
  }

  #testimonials .carousel-item {
    padding: 2%;
  }
}

.section-title {
  text-align: -webkit-center;
  text-align: center;
}

.oep-home-section {
  padding: 60px 0px;
}
.oep-home-section.first-banner {
  padding: 0px;
  position: relative;
  display: flex;
}
.first-banner .carousel-item {
  min-height: 800px;
}
.first-banner .carousel-inner,
.first-banner #home-main {
  height: 100%;
}
.homepg-bg {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  width: 100vw;
}

#home-main .carousel-inner img {
  width: 100%;
  height: 100%;
}
.first-banner .overlay-bg,
.first-banner .abs-parent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.first-banner .scroll-down {
  position: absolute;
  bottom: 7%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.first-banner .scroll-down > small {
  margin-top: 5px;
  font-weight: 100;
  font-size: 13px;
}
.first-banner .scroll-down-btn {
  padding: 7px 12px;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 17px;
}
.first-banner .scroll-down-btn > svg {
  -webkit-animation: jump 1s infinite ease-in-out;
          animation: jump 1s infinite ease-in-out;
}
@-webkit-keyframes jump {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes jump {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(0px);
  }
}
.first-banner .overlay-bg {
  background-color: rgb(0 0 0 / 73%);
}
.first-banner .main-title {
  position: absolute;
  width: 100%;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -38%);
  color: white;
  z-index: 99;
  font-weight: 300;
  text-align: center;
  font-size: 4vw;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
}
.why-we-here {
  background: linear-gradient(45deg, rgb(0, 0, 0), #163283, rgb(14, 107, 210));
  color: #fff;
}

.why-we-here-container {
  position: relative;
  text-align: center;
}

.why-we-here-img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 15px 50px 30px;
}

@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .why-we-here-img {
    height: 100%;
    padding: 50px 0px !important;
  }
}

.why-we-here-content {
  font-weight: 500;
  padding: 45px 0px 10px 0px;
  font-size: 21px;
  text-align: justify;
}

#home-whywehere-title {
  color: rgb(255, 255, 255);
}

.why-we-here-list {
  list-style: disc;
  text-align: left;
  padding-left: 40px;
  font-size: 21px;
  font-weight: 600;
  color: #ffffff;
}
.why-we-here-list > li {
  margin-bottom: 6px;
}
.svg-section {
  height: 180px;
  width: 100%;
}

.svg-section img {
  width: 100%;
}

/* Section title styles */
.section-title {
  text-align: -webkit-center;
  text-align: center;
  color: rgb(33, 150, 243);
  position: relative;
  font-weight: 700;
}

.section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 25%;
  bottom: -20px;
  -webkit-transform: scaleX(1);
  -webkit-transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
  -moz-transform: scaleX(1);
  -moz-transition: transform 250ms ease-in-out;
  -moz-animation: expandCenter 1s ease-in-out;
  -o-transform: scaleX(1);
  -o-transition: transform 250ms ease-in-out;
  -o-animation: expandCenter 1s ease-in-out;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
}

@-webkit-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.main-content-heading {
  color: #1e7dec;
  padding-bottom: 1.5rem;
  padding-right: 2em;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 1.3px;
}

.main-content-detail {
  padding-bottom: 0.5em;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 400;
  color: rgb(88, 88, 88);
}

.main-content-button {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  margin-top: 15px;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  background-color: transparent;
  border: 2px solid white;
  box-shadow: 1px 9px 11px rgb(29 28 28 / 82%);
  overflow: hidden;
  outline: none;
  transition: 0.3s all;
}

.main-content-button span {
  z-index: 20;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.main-content-button::after {
  background: rgb(255, 255, 255);
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 10;
}

.main-content-button:hover span {
  font-weight: 500;
}

.main-content-button:hover::after {
  left: 100%;
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.main-content-button:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: #0070f7;
  box-shadow: 4px 5px 12px rgb(24, 23, 23);
  border-color: transparent;
}

.main-content-button:hover > .interested-icon {
  -webkit-animation: moveLeftRight 0.8s infinite ease-in-out;
  animation: moveLeftRight 0.8s infinite ease-in-out;
}

@-webkit-keyframes moveLeftRight {
  from {
    right: 20px;
  }

  to {
    right: 5px;
  }
}

@keyframes moveLeftRight {
  from {
    right: 20px;
  }

  to {
    right: 5px;
  }
}

.interested-icon {
  padding-left: 6px;
  position: absolute;
  right: 20px;
}
.main-theme-content {
  align-self: center;
}
.main-theme-img {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 5%;
}

#query-section {
  background-color: rgb(250, 250, 250);
  background: rgb(250, 250, 250);
}

.query-section-title {
  color: rgb(0, 0, 0);
  position: relative;
  font-weight: 700;
}

.query-section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(90, 151, 66);
  left: 0%;
  bottom: -20px;
  -webkit-transform: scaleX(1);
  -webkit-transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
  -moz-transform: scaleX(1);
  -moz-transition: transform 250ms ease-in-out;
  -moz-animation: expandCenter 1s ease-in-out;
  -o-transform: scaleX(1);
  -o-transition: transform 250ms ease-in-out;
  -o-animation: expandCenter 1s ease-in-out;
  transition: transform 250ms ease-in-out;
  transform: scaleX(1);
  animation: expandCenter 1s ease-in-out;
}

.home-popular-courses {
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
}

.card-section {
  margin-top: 30px;
  border-radius: 5px;
  background-color: rgb(250, 250, 250);
  width: 100%;
  padding: 15px;
}

.query-theme-img {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding-top: 15%;
}

@media (max-width: 990px) and (min-width: 775px) {
  .main-content-detail {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 990px) and (min-width: 775px) {
  .svg-section {
    height: 120px;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .svg-section {
    height: 80px;
    width: 100%;
  }
  .first-banner .carousel-item {
    min-height: 500px;
  }
}

@media (max-width: 576px) {
  .first-banner .main-title {
    font-size: 2em;
    white-space: unset;
    top: 32%;
    transform: translate(-50%, -32%);
  }
  .first-banner .main-content-button {
    margin-top: 15%;
  }
}

/* Below styles are fore temporary purpose and used to build the skeleten layout. */

.oep-course-section {
  padding: 15px 0px;
  background-color: #fbfbfb;
}

.courses-section-title {
  position: relative;
  font-weight: 700;
  max-width: 250px;
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  left: 10px;
  font-size: 30px;
  color: #0b7ee0;
}

.courses-section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-animation: expandToRight 1s ease-in-out;
          animation: expandToRight 1s ease-in-out;
}

@-webkit-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

.courses-carousel-container {
  height: 350px;
}

#all-courses {
  padding-top: 80px;
}

@media screen and (max-width: 991.98px) {
  .courses-section-title {
    font-size: 1.5em;
  }
}

.courses-header-container {
  padding: 20px 0px !important;
  position: relative;
  background-image: url(/static/media/coursesHeader.0b44e4b2.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.73);
  height: auto;
}

.courses-header-container::after {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73);
  opacity: 0.9;
}

.course-category-header {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0px 15px;
  height: auto;
}

.header-style {
  color: rgb(255, 250, 240);
  font-weight: 600;
}

.subtitle {
  position: relative;
  height: 75px;
  padding: 15px 0px;
  height: auto;
}

.header-subtile {
  padding: 10px 5px;
  color: rgb(245, 245, 245);
  font-style: italic;
  font-weight: 600;
}

.navpills {
  position: relative;
  z-index: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.course-pills-skeleton {
  margin: 7px;
}

.course-pills-skeleton span {
  border-radius: 30px;
}

.course-pills-bg {
  padding: 3px 20px;
  margin: 7px;
  color: rgb(255, 255, 255);
  text-align: center;
  position: relative;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1.2px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  position: relative;
  overflow: hidden !important;
  transition: all 0.3s ease-in-out;
  background: transparent !important;
  z-index: 10;
}

.course-pills-bg:hover {
  border: 1px solid transparent;
  color: rgb(255, 255, 255) !important;
  text-decoration: none;
}

.course-pills-bg::before {
  content: "";
  width: 0%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to top, rgb(68, 129, 235) 0%, rgb(4, 190, 254) 100%);
  position: absolute;
  transform: skewX(-20deg);
  left: -10%;
  opacity: 1;
  top: 0;
  z-index: -12;
  transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6);
}

.course-pills-bg::after {
  content: "";
  width: 0%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to top, rgb(207, 217, 223) 0%, rgb(226, 235, 240) 100%);
  position: absolute;
  transform: skewX(-20deg);
  left: -10%;
  opacity: 0;
  top: 0;
  z-index: -15;
  transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6);
}

.course-pills-bg:hover::before {
  opacity: 1;
  width: 116%;
}

.course-pills-bg:hover::after {
  opacity: 1;
  width: 125%;
}

.load-button {
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2px;
  outline: none;
  margin: 10px;
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: sans-serif;
  text-align: center;
  text-align: -webkit-center;
  position: relative;
}

.load-button:hover {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

.load-button:focus {
  outline: 0;
  box-shadow: none;
}



@media screen and (max-width:572px) {
  .course-pills-bg {
    font-size: 15px;
  }
}
/* ************** */

article.course-list-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.4s all ease;
  flex: 0 0 auto;
  background: white;
}

article.course-list-card:hover {
  box-shadow: 2px 8px 11px rgb(206, 206, 206);
}

article.course-list-card .thumbnail {
  flex: 0 0 250px;
  border-radius: 3px;
  position: relative;
}

article.course-list-card .view-more {
  opacity: 0;
  color: rgb(3, 169, 244);
  flex: 0 0 100px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: 0.4s all ease;
}

article.course-list-card:hover .view-more {
  opacity: 1;
}

article.course-list-card .thumbnail > img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 3px;
}

article.course-list-card .course-details {
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  padding: 14px 24px;
  background: rgb(255, 255, 255);
  transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-list-card .course-details .course-title {
  position: relative;
  margin-bottom: 10px;
  color: rgb(21, 37, 54);
  font-size: 1.25rem;
  text-shadow: 0 0 0px rgb(50, 87, 127);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

article.course-list-card .course-details .course-card-rating {
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

article.course-list-card .course-details .course-card-rating > span {
  position: relative;
  top: 4px;
  margin-right: 10px;
}

article.course-list-card .course-details .more-info {
  position: relative;
}

article.course-list-card .course-details .course-summary {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  font-size: 16px;
  color: rgba(21, 37, 54, 0.7);
  padding-right: 10%;
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-list-card .view-more:hover {
  background-color: #03a9f4;
  color: rgb(255, 255, 255);
}

article.course-list-card .view-more:hover .view-more-arrow {
  border-color: rgb(255, 255, 255);
}

article.course-list-card .view-more .view-more-arrow {
  font-weight: 100;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid rgb(3, 169, 244);
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  position: relative;
}

article.course-list-card .view-more .view-more-arrow > svg {
  font-weight: 100;
  position: absolute;
  top: 11px;
  left: 11px;
}

@media screen and (max-width: 767.98px) {
  article.course-list-card .thumbnail {
    display: none;
  }

  article.course-list-card .course-details .course-title {
    font-size: 1em;
  }

  article.course-list-card .course-details .course-summary {
    padding-right: 0%;
    font-size: 15px;
  }

  article.course-list-card .course-details {
    padding: 0px 7px;
  }
}

@media screen and (max-width: 991.98px) {
  article.course-list-card .view-more {
    display: none;
  }

  article.course-list-card .course-details .course-summary {
    padding: 0px;
  }
}

.courses-list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  overflow-x: hidden;
  padding: 30px;
}

.courses-list.empty {
  height: auto;
  overflow: hidden;
  min-height: auto;
  max-height: auto;
  padding: 5px;
}

.courses-list.grid {
  flex-wrap: wrap;
  flex-direction: row !important;
}

.courses-list.list {
  flex-direction: column !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: 0 0 1px rgba(51, 51, 51, 0.685);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(126, 126, 126, 0.8);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(99, 99, 99, 0.4);
}

.courses-header {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}

.search-courses {
  flex: 0 1 50%;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 15px;
}

.search-course-container {
  position: relative;
}

.search-course-container .clear-search-input {
  position: absolute;
  right: 6px;
  top: 5px;
  background-color: #e1f2ff;
  border: none;
  padding: 3px 8px;
  border-radius: 4px;
  color: #525252;
  font-size: 15px;
}

.search-courses .all-course-search {
  width: 100%;
  padding: 9px 18px;
  border: 1px solid #e4e4e400;
  border-radius: 4px;
  margin-bottom: 15px;
  box-shadow: 2px 4px 20px rgb(222 222 222);
  color: #403c3c;
  background-color: white;
}

.search-courses .all-course-search::-webkit-input-placeholder {
  font-size: 16px;
  color: rgb(107, 105, 105) !important;
  font-style: italic;
}

.search-courses .all-course-search:-ms-input-placeholder {
  font-size: 16px;
  color: rgb(107, 105, 105) !important;
  font-style: italic;
}

.search-courses .all-course-search::-ms-input-placeholder {
  font-size: 16px;
  color: rgb(107, 105, 105) !important;
  font-style: italic;
}

.search-courses .all-course-search::placeholder {
  font-size: 16px;
  color: rgb(107, 105, 105) !important;
  font-style: italic;
}

.search-courses .search-results-info {
  color: rgb(83, 83, 83);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: none;
}

.search-courses .search-results-info.show {
  display: block !important;
}

.search-courses .search-results-info strong {
  font-size: 18px;
}

.search-courses .search-results-info small {
  margin: 0px 5px;
}

.search-courses .all-course-search::-webkit-input-placeholder {
  font-size: 15px;
  color: rgb(85, 85, 85);
}

.search-courses .all-course-search:-ms-input-placeholder {
  font-size: 15px;
  color: rgb(85, 85, 85);
}

.search-courses .all-course-search::-ms-input-placeholder {
  font-size: 15px;
  color: rgb(85, 85, 85);
}

.search-courses .all-course-search::placeholder {
  font-size: 15px;
  color: rgb(85, 85, 85);
}

.search-courses .suggestions {
  position: absolute;
  width: 100%;
}

.course-search {
  padding: 5px;
  height: 45px;
  display: flex;
  align-items: center;
  border: none;
  font-size: 15px;
  border-radius: 30px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: -1px 2px 8px rgba(224, 224, 224, 0.741);
}

.courses-header .course-action {
  display: flex;
  display: -webkit-flex;
  flex: 0 0 200px;
}

.filter-option {
  position: relative;
  padding: 7px 15px;
  color: rgb(128, 128, 128);
  cursor: pointer;
}

.course-view-option {
  display: flex;
  display: -webkit-flex;
  padding: 2px 15px;
  box-shadow: 2px 4px 20px rgb(222 222 222);
  border-radius: 4px;
  height: 50px;
}

.course-view-option .grid-view,
.course-view-option .list-view {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px 7px;
  font-size: 30px;
  transition: 0.5s ease-in-out;
  outline: none;
}
.course-view-option .grid-view:focus,
.course-view-option .list-view:focus {
  outline: none;
}

.course-view-option svg {
  padding: 5px;
  color: rgb(142, 142, 142);
  transition: 0.5s all;
}

.course-view-option .grid-view:not(.active):hover svg,
.course-view-option .list-view:not(.active):hover svg {
  color: rgb(135, 135, 136);
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
  box-shadow: 2px 0px 11px rgb(230, 230, 230);
}

.course-view-option .grid-view.active svg,
.course-view-option .list-view.active svg {
  color: rgb(76, 137, 249);
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
  box-shadow: 2px 0px 11px rgb(230, 230, 230);
}

.filter-icon {
  height: 40px;
  width: 40px;
  font-size: 25px;
  padding: 1px 7px;
  transition: 0.5s all ease-in-out;
}

.filter-icon:hover {
  color: rgb(135, 135, 136);
  background-color: rgb(241, 240, 240);
  border-radius: 30px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  min-width: 150px;
  box-shadow: 4px 8px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin: 0px;
  padding: 0px 25px;
  top: px;
  left: -110px;
  border-radius: 5px;
  padding: 15px 3px;
}

/* Links inside the dropdown */
.dropdown-content li {
  color: rgb(78, 78, 78);
  padding: 5px 12px;
  display: block;
  font-size: 15px;
  transition: 0.4s ease-in-out;
}

/* Change color of dropdown links on hover */
.dropdown-content li:hover {
  background-color: rgb(243, 243, 243);
}

/* Show the dropdown menu on hover */
.dropdown .dropdown-content.show {
  display: block;
  cursor: pointer;
}

/* No course container styles */
.no-course-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.no-course-container .title {
  position: absolute;
  font-size: 45px;
  color: #e0e0e0;
  font-weight: 700;
  letter-spacing: 0.8px;
  top: 40px;
}

.no-course-container .no-course-img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

/* load more */
.btn-load-more {
  padding: 10px 20px;
  box-shadow: 1px 7px 10px #cecece;
  background-color: #fff;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #7b7b7b;
  transition: 0.4s all ease-in-out;
}

.btn-load-more .fa-spinner {
  -webkit-animation: rotate 1s infinite;
          animation: rotate 1s infinite;
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-load-more:hover,
.btn-load-more:focus {
  background-color: rgb(43, 141, 253);
  color: #f7f7f7;
  box-shadow: 1px 7px 20px #b4b3b3;
  outline: none;
}

/* Change the background color of the dropdown button when the dropdown content is shown */

@media screen and (max-width: 991.98px) {
  .courses-list.grid {
    justify-content: center;
  }
}

@media screen and (max-width: 572px) {
  .no-course-container .title {
    font-size: 20px;
    top: 20px;
  }

  .courses-header {
    display: block;
  }

  .search-courses {
    padding: 10px 0px;
  }

  .courses-header .course-action {
    flex: 0 0;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}

.detail-header {
    padding: 30px 0px;
}

.course-detail-title {
       color: #1a81f1;
    padding-bottom: 2rem;
    font-size: 35px;
    font-weight: 700;
}

.course-detail-content {
    padding-bottom: 0.25rem;
    font-weight: 400;
    font-size: 18px;
    color: rgb(102, 99, 99);
    letter-spacing: 0.5px;
    line-height: 1.6;
}

.course-detail-rating {
    font-weight: 600;
    font-size: 20px;
    position: relative;
}

.course-detail-rating>span {
    position: relative;
    top: 4px;
    margin-right: 10px
}

.discount-banner {
    padding: 15px;
    color: rgb(255, 255, 255);

    background: #ffb347;
    /* fallback for old browsers */
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ffcc33, #ffb347);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-animation: floatingCard 2.5s infinite ease-in-out;
            animation: floatingCard 2.5s infinite ease-in-out;
    text-align: center;
    text-align: -webkit-center;
    margin: 15px 0px;
    border-radius: 5px;
    box-shadow: 1px 9px 11px #c2c2c2;
}

@keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 10px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-webkit-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

.discount-banner .text {
    font-weight: 600;
    font-size: 1.5em;
}

.discount-banner .discount-rate {
    font-size: 1.7em;
}


.duration-sm {

    font-size: 1.4em;
    margin: 10px 0px 0px 0px;
    color: rgb(59, 59, 59);
}

.duration-sm strong {
    font-size: 1.4em;
    color: rgb(32, 156, 255);
}

.duration-sm label,
.duration-sm span {
    margin-right: 5px;
}

@media (max-width: 599px) {
    .course-detail-title {
        line-height: 2.5rem !important;
    }

    .duration-sm {
        font-size: 1.1em !important;
    }

    .discount-banner .text {
        font-size: 1.1em !important;
    }
}
.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /*16:9 Aspect Ratio*/
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 3px;
  background-color: rgb(230, 230, 230);
}

.responsive-skeleton-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/* Remove below .temp while developing */
.course-content-section {
  padding: 30px 0px;
}

.angle-down-icon {
  float: right;
  padding-right: 5px;
  color: rgb(167, 164, 164);
  font-size: 1.2em;
  cursor: pointer;
}

.angle-up-icon {
  float: right;
  padding-right: 5px;
  color: rgb(167, 164, 164);
  font-size: 1.2em;
  cursor: pointer;
}
[aria-expanded="true"] .angle-down-icon {
  display: none;
}
[aria-expanded="false"] .angle-up-icon {
  display: none;
}

.toDisplayinline {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}

@media screen and (max-width: 400px) {
  .toDisplayinline {
    justify-content: none;
  }
}
.session-title {
  font-size: 17.5px;
  font-weight: 600;
  justify-content: center;
  align-self: center;
}
.arrow-icons {
  justify-content: center;
  align-self: center;
  padding-right: 5px;
}
.course-content-card {
  background-color: rgb(248, 248, 248);
  padding: 10px;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 6px;
  border: 1px solid rgb(243, 243, 243);
}

.sub-header-container,
.child-header-container {
  border-left: 1px solid rgb(212, 211, 211);
  padding-bottom: 12px;
  padding-left: 30px;
  padding-top: 12px;
  margin-left: 15px;
  margin-right: 5px;
  font-size: 17px;
  font-weight: 500;
  color: #545151;
}
.child-header-container {
  list-style-type: none;
  padding-left: 25px;
}
.sub-header {
  padding: 5px;
  color: black;
  background: rgb(255, 255, 255);
  display: block;
  margin-left: 5px;
}

.expand-all {
  display: inline-block;
  /* padding: 5px; */
  text-align: end;
  border: none;
  margin: 0;
  background: transparent;
  color: rgb(0, 174, 255);
}

.expand-all:hover,
.expand-all:focus {
  outline: none;
  cursor: pointer;
}

.discount-badge-container,
.discount-box {
  position: relative;
}

.discount-box {
  height: 250px;
}

.discount-badge-container p {
  position: absolute;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.disc-badge {
  width: 130px;
  position: absolute;
  right: 0px;
  height: 210px;
}

.discount-rate {
  right: 6px;
  font-size: 2.5em;
  top: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  width: 120px;
  text-align: center;
}

.disc-pc {
  position: absolute;
  width: 285px;
  left: -4px;
  top: 88px;
}

/* Remove below .temp while developing */

.duration-box {
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 6px 20px #cfcece;
  margin: 15px 0px;
  background-color: rgb(248, 248, 248);
}

.man-with-hourglass {
  flex: 0 0 210px;
  width: 210px;
  max-width: 210px;
  height: 100%;
}

.duration-specifier-wrap {
  flex: 1 1 auto;
  text-align: -webkit-center;
  text-align: center;
  align-self: center;
}
.duration-specifier-wrap h3 {
  font-weight: 600;
  color: #8c8787;
  font-size: 25px;
}
.duration-specifier-wrap h2 {
  font-size: 90px;
  color: #4992d94d;
  font-weight: 900;
}

.duration-specifier-wrap small {
  font-size: 30px;
  color: #8c8787;
  font-weight: 800;
}

/* Remove below .temp while developing */
.mini-query-section {
    position: sticky;
    position: -webkit-sticky !important;
    top: 80px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0px 6px 20px #cfcece;
}

.beSticky {
    position: fixed;
    width: 368px !important;
    transform: translate(0, 10%);
    -webkit-animation: floatingCard 2.5s infinite ease-in-out;
    /* Safari 4+ */
    /* Fx 5+ */
    /* Opera 12+ */
    animation: floatingCard 2.5s infinite ease-in-out;
}

.absolute {

    transition: 0.5s all ease;
    -webkit-animation: fadeOut 1.5s ease-in-out;
            animation: fadeOut 1.5s ease-in-out;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.7;
    }

    75% {
        opacity: 0.4;
    }

    100% {
        position: absolute;
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.7;
    }

    75% {
        opacity: 0.4;
    }

    100% {
        position: absolute;
        opacity: 0;
    }
}

.beSticky:hover {
    -webkit-animation: none;
            animation: none;
    transform: translate(0px, 0px);
}

@keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 10px);
    }

    to {
        transform: translate(0, -0px);
    }
}

@-webkit-keyframes floatingCard {
    from {
        transform: translate(0, 0px);
    }

    65% {
        transform: translate(0, 15px);
    }

    to {
        transform: translate(0, -0px);
    }
}

.mini-query-sendicon {
    padding-left: 20px
}

.mini-query-input {
    border: none;
    width: 100%;
    background-color: none;
}


.mini-query-input::-webkit-input-placeholder, .mini-query-text-box::-webkit-input-placeholder {
    font-style: italic;
}


.mini-query-input:-ms-input-placeholder, .mini-query-text-box:-ms-input-placeholder {
    font-style: italic;
}


.mini-query-input::-ms-input-placeholder, .mini-query-text-box::-ms-input-placeholder {
    font-style: italic;
}


.mini-query-input::placeholder,

.mini-query-text-box::placeholder {
    font-style: italic;
}

.mini-query-fields {
    padding-top: 20px;
    border-bottom: 1px solid rgb(128, 128, 128);
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 10px;
}


.mini-query-submit-btn {
    padding: 10px 25px;
    color: rgb(82, 143, 255);
    background: transparent;
    border: 1px solid rgb(82, 142, 251);
    font-weight: 700;
    transition: 0.5s all ease-in-out;

}

.mini-query-submit-btn:focus {
    outline: none;
}

.mini-query-submit-btnicon {
    padding-left: 20px;
}


.mini-query-header {
    text-align: center;
    font-weight: 700;
    padding: 10px;
}

.mini-query-header::after {
    content: "";
    display: inline-block;
    position: relative;
    width: 45%;
    border-radius: 15px;
    height: 4px;
    background-color: rgb(82, 142, 251);
    left: -1%;
    bottom: 10px;
    transform: scaleX(1);
    transition: transform 250ms ease-in-out;
    -webkit-animation: expandCenter 1s ease-in-out;
            animation: expandCenter 1s ease-in-out;
}

.mini-query-text-box {
    width: 100%;
    height: 100%;
    padding: 5px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    max-height: 105px;
    min-height: 50px;
}

.mini-query-submit-btn {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);
    border-radius: 2px;
    box-shadow: 0 0 1px transparent;
}

.mini-query-submit-btn:before {
    content: '';
    position: absolute;
    border: rgb(82, 142, 251) solid 2px;
    border-radius: 2px;
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: -16px;
    opacity: 0;
    transition-duration: .3s;
    transition-property: top right bottom left;
}

.mini-query-submit-btn:hover:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    opacity: 1;
}

.mini-query-submit-btn:hover {
    background-color: rgb(82, 142, 251);
    color: rgb(255, 255, 255);
}

.mini-query-fields .react-tel-input {
    width: 100%;
    border: 0px solid transparent !important;
    background-color: transparent !important;
}

.mini-query-fields .react-tel-input .form-control {
    background-color: transparent !important;
    border: 0px solid transparent !important;

}

.mini-query-fields .react-tel-input .flag-dropdown {
    border: 0px solid transparent !important;
    background-color: transparent !important;
}
.modes-training-section {
  padding: 30px 0px;
}

.mode-card-wrapper {
  width: 100%;
}

.mode-card-container {
  width: 320px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  color: rgb(65, 65, 65);
  box-shadow: 0px 0px 20px 11px #efececcc;
  border-radius: 5px;
  padding: 1.4rem;
  position: relative;
  transition: 0.2s all;
}

.flex-wrapper {
  flex-direction: row;
}

.mode-card-container:hover {
  box-shadow: 0px 0px 20px 10px #ebeaeacc;
  transform: translate(0px, -5px);
  -webkit-transform: translate(0px, -5px);
  -o-transform: translate(0px, -5px);
  -moz-transform: translate(0px, -5px);
}
.mode-card-container:hover .training-mode-brief {
  color: rgb(61, 58, 58);
}
/* header styles */
.training-mode-header {
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;
  margin-bottom: 15px;
  font-size: 1.3em;
  color: rgb(70, 70, 70);
}

.training-mode-brief {
  padding: 0px 15px;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 25px;
  width: 100%;
  color: #a09f9f;
  text-align: -webkit-center;
  text-align: center;
  transition: color 0.5s ease-in-out;
}

.mode-training-doubts {
  display: flex;
  display: -webkit-flex;
  padding: 19px;
  /* padding-left: 18%; */
  font-size: 20px;
  font-weight: 700;
  color: rgb(169, 169, 169);
  letter-spacing: 1px;
  width: 100%;
  max-width: 760px;
  text-align: center;
  justify-content: center;
}

.mode-training-doubts a {
  text-decoration: none;
}

.mode-training-doubts a:hover .query-call-icon {
  -webkit-animation: shake 0.6s infinite 0.5s;
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: shake 0.6s infinite 0.5s;
}

@-webkit-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media screen and (max-width: 991.98px) {
  .mode-card-container {
    width: 50%;
    margin: 0px 15px !important;
  }
}

@media screen and (max-width: 762px) {
  .flex-wrapper {
    flex-direction: column;
  }

  .mode-training-doubts p {
    font-size: 0.9em;
  }

  .mode-card-container {
    width: 100%;
    margin: 10px 0px !important;
  }
}

/* Remove below .temp while developing */
.temp-upcoming-events {
    font-size: 1.2em;
    padding: 30px 0px;
    border: 1px solid rgb(255, 0, 0);
    min-height: 350px;
}

.upcoming-batch-container {
    height: 150px;
}

article.event-card {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 8px rgb(221, 221, 221);
    border-radius: 5px;
    cursor: pointer;
    margin: 15px;
    padding: 15px;
    transition: 0.4s cubic-bezier(.86, 0, .07, 1);
    min-width: 225px;
    position: relative;
}

article.event-card:hover {
    box-shadow: 1px 6px 10px rgb(197, 197, 197);
}

article.event-card .rounded-event-time {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgba(243, 75, 75, 0.815);
    margin: 0px 5px;
    position: relative;
}

article.event-card .event-date {
    font-weight: 600;
    font-size: 1.6em;
    color: rgb(255, 255, 255);
    display: block;
    position: absolute;
    top: -1px;
    left: 15px;
}

article.event-card .event-month {
    font-weight: 500;
    font-size: 1em;
    color: rgb(248, 248, 248);
    display: block;
    position: absolute;
    top: 29px;
    left: 16px;
}

article.event-card .event-time {
    flex: 1 1 auto;
    color: rgb(109, 109, 109);
    font-weight: 500;
    font-size: 1.2em;
    align-self: center;
    min-width: 100px;
    padding-left: 10px;
}

.book-for-events {
    display: flex;
    align-items: baseline;
    padding: 15px 30px;
    background-color: #fafafa;
    width: 100%;
}

.book-for-events p {
    font-size: 1.1em;
    font-weight: 500;
    margin: 0px;
    color: rgb(99, 99, 99);
    letter-spacing: 0.5px;
}

.book-for-events .book-events-btn {
    background-color: rgb(65, 105, 225);
    color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 7px 15px;
    font-size: 1.2em;

    font-weight: 600;
    margin: 15px;
    box-shadow: 4px 4px 0px rgb(189, 189, 189);
    transform: translate(-3px, -4px);
    transition: 0.4s cubic-bezier(.86, 0, .07, 1);
}

.book-for-events .book-events-btn:hover {
    box-shadow: 0px 0px 8px rgb(160, 160, 160);
    transform: translate(0px, 0px);
}

/* Events discount badge styles */
.event-discount-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    border-top: 40px solid transparent;
    border-left: 65px solid transparent;
    border-bottom: 50px solid rgba(245, 161, 26, 0.96);
    background-color: transparent;
    -webkit-animation: superNova 5s infinite ease-in-out;
            animation: superNova 5s infinite ease-in-out;


}

@keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgba(255, 174, 0, 0.966);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}

@-webkit-keyframes superNova {
    0% {
        border-bottom-color: rgb(231, 71, 71);
    }

    25% {
        border-bottom-color: rgb(54, 179, 54);
    }

    50% {
        border-bottom-color: rgb(75, 134, 189);
    }

    75% {
        border-bottom-color: rgb(218, 135, 11);
    }

    100% {
        border-bottom-color: rgba(216, 89, 5, 0.96);
    }
}


#course-event-discount {
    position: absolute;
    left: -35px;
    top: 23px;
    margin: 0px;
    font-weight: 500;
    color: rgb(255, 255, 255);
}


.upcoming-course-wrapper {
    position: relative;
    height: 200px;
    margin: 1rem 0;
    width: 95%;
}

.upcoming-course-container {
    height: 100%;
    position: absolute;
    width: 100%;
    padding: 0 1em;
}



/* caruosel styles */
.next-event-btn,
.prev-event-btn {
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    z-index: 99;
    box-shadow: 0px 3px 11px rgb(144, 144, 144);
    position: absolute;
    top: calc(50% - 30px);
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
    -moz-transition: 0.5s all ease-in-out;
    -o-transition: 0.5s all ease-in-out;

}

.next-event-btn {
    right: -45px !important;
}

.prev-event-btn {
    left: -40px !important;
}

.next-event-btn:hover,
.prev-event-btn:hover {
    border: 1px solid rgb(216, 216, 216);
    box-shadow: 1px 1px 10px rgb(221, 221, 221);
}

.next-event-btn:hover svg,
.prev-event-btn:hover svg {
    color: rgb(124, 124, 124) !important;

}

.prev-event-btn>svg,
.next-event-btn>svg {
    color: rgb(37, 122, 207);
    font-size: 22px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.next-event-btn>svg {
    left: 13px;
}

/*  */

@media screen and (max-width: 572px) {
    article.event-card {
        margin: 10px 5px;
        flex: 1 1 25%;
    }

    .book-for-events p {
        font-size: 0.9em;
    }
}
.related-courses-section {
    padding: 60px 0px;
}

.related-course-wrapper {
    position: relative;
    height: 350px;
    margin: 2.5rem 0;
    width: 100%;

}

.related-course-container {
    height: 100%;
    position: absolute;
    width: 100%;
    padding: 0 2.1em;
}

.related-course-carousel {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width:572px) {
    .related-course-wrapper {
        height: 300px;
        margin: 1.5rem 0rem !important;
        padding: 1rem 0.5rem !important;
    }

    .related-course-container {
        padding: 0px;
    }

    .related-course-carousel {
        max-width: 90%;
        margin: 0px auto !important;
    }
}
.course-detail-main {
  display: flex;
  display: -webkit-flex;
  padding: 0px 50px;
}

.mini-query-section,
.duration-box,
.discount-box {

  width: 100%;
}

@media screen and (min-width:1399.98px) {
  .course-detail-main {
    padding: 0px 100px;
    width: 90%;
    margin: 0px auto;
  }
}

@media screen and (max-width:761.98px) {
  .course-detail-main {
    padding: 0em 1em;

  }

}

@media screen and (max-width:1199.98px) {
  .course-detail-main .right-container {
    display: none !important;
  }
}

@media screen and (max-width:1199.98px) {
  .course-detail-main .right-container {
    display: none !important;
  }
}

.course-detail-main .left-container {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.course-detail-main .right-container {
  display: block;
  flex: 0 0 400px;
  position: relative;

}

.course-detail-main .right-container .wrapper {
  position: relative;
  max-width: 400px;

}

.course-section-title {
  display: inline-block;
  color: black;
  font-size: 1.5rem;
  position: relative;
  font-weight: 700;
}

.course-section-title::after {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  border-radius: 15px;
  height: 4px;
  background-color: orange;
  left: 0%;
  bottom: -10px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
          animation: expandCenter 1s ease-in-out;
}
.social-snack-bar {
  position: fixed;
  width: 65px;
  top: 40%;
  left: 4px;
  border-radius: 5px;
  z-index: 99;
}

.social-links-container {
  margin: 0px;
  list-style-type: none;
  padding: 10px;
}

.social-links-container .social-link-nav {
  padding: 23px;
  cursor: pointer;
  border-radius: 30px;
  margin: 8px 0px;
  position: relative;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  height: 46px;
  width: 46px;
}

.social-links-container .social-link-nav > a {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #565656;
  cursor: pointer;
}
.social-links-container .social-link-nav > a > svg {
  color: #fff;
  font-size: 1.6em;
}

.social-link-info {
  display: none;
  position: absolute;
  opacity: 0;
  height: 46px;
  width: 350px;
  font-size: 15px;
  font-family: sans-serif;
  color: #565656;
  padding: 12px 15px;
  padding-left: 60px;
  left: 1px;
  top: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  z-index: -1;
  border: 1px solid rgb(229, 229, 229);
}

.social-links-container .social-link-nav:hover svg {
  font-size: 1.5em;
  transform: scale(1.2);
  -webkit-animation: ScaleUp 0.7s ease-in-out;
  animation: ScaleUp 0.7s ease-in-out;
}

.social-links-container .social-link-nav.whatsapp-link svg,
.social-links-container .social-link-nav.whatsapp-link:hover svg {
  font-size: 1.7em;
}

.social-link-nav.whatsapp-link {
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
}

.social-link-nav.facebook-link {
  background-color: rgb(59, 89, 153);
  color: rgb(255, 255, 255);
}
.social-link-nav.youtube-link {
  background-color: rgb(214, 28, 28);
  color: rgb(255, 255, 255);
}
.social-link-nav.insta-link {
  background: rgb(228, 64, 95);
  color: rgb(255, 255, 255);
}

.social-link-nav.twitter-link {
  background-color: rgb(85, 172, 238);
  color: rgb(255, 255, 255);
}

.social-link-nav.LinkedIn-link {
  background-color: rgb(0, 119, 181);
  color: rgb(255, 255, 255);
}

.social-link-nav.whatsapp-link:hover {
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
}

.social-link-nav.facebook-link:hover {
  background-color: rgb(59, 89, 153);
  color: rgb(255, 255, 255);
}

.social-link-nav.insta-link:hover {
  background-color: rgb(228, 64, 95);

  color: rgb(255, 255, 255);
}

.social-link-nav.twitter-link:hover {
  background-color: rgb(85, 172, 238);
  color: rgb(255, 255, 255);
}

.social-link-nav.LinkedIn-link:hover {
  background-color: rgb(0, 119, 181);
  color: rgb(255, 255, 255);
}

@keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
@-webkit-keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.oep-contact-section {
  padding: 15px 10px;
}

.oep-contact-section {
  background-color: #f7f7f7;
}

.top-section-contact {
  padding: 10%;
}

.top-contact-heading {
  text-align: center;
  text-align: -webkit-center;
  color: rgb(33, 150, 243);
  padding-bottom: 24px;
  font-size: 40px;
  font-weight: 800;
}

.top-contact-content {
  padding-bottom: 0.5rem;
  font-weight: 500;
  font-size: 20px;
  color: rgb(142, 150, 165);
  text-align: center;
  text-align: -webkit-center;
  letter-spacing: 0.5px;
}

.top-contact-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 6%;
}

.fade-in {
  animation: fadeInRight ease 1.5s 0.5s forwards;
  /* IE 10+, Fx 29+ */
  -webkit-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Safari 4+ */
  -moz-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Fx 5+ */
  -o-animation: fadeInRight ease 1.5s 0.5s forwards;
  /* Opera 12+ */
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(250px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.contactIcon {
  padding-left: 8px;
  padding-right: 8px;
}

.card-section-query {
  border-radius: 7px;
  background-color: rgb(255 255 255);
  width: 100%;
  height: auto;
  padding: 15px;
  box-shadow: 2px 0px 20px #dedede;
}
.query-section-heading {
  text-align: center;
  text-align: -webkit-center;
  color: rgb(0, 0, 0);
  position: relative;
  font-weight: 700;
}

.query-section-heading::after {
  content: "";
  display: block;
  position: absolute;
  width: 40%;
  margin-left: 30%;
  border-radius: 15px;
  height: 3px;
  background-color: rgb(240, 79, 30);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
          animation: expandCenter 1s ease-in-out;
}

@-webkit-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.query-section-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding-top: 15%;
}

.query-background {
  display: block;
  transform: scale(0.9);
  width: 100%;
  height: 100%;
}

.query-card-container {
  margin: 10px 0px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.query-card {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 280px;
  padding: 30px;
  border-radius: 7px;
  background-color: inherit;
  border: 1px solid transparent;
  transition: 0.3s all;
}

.query-card-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  padding: 25px 0px;
}

.query-card-title {
  text-align: center;
  text-align: -webkit-center;
  position: relative;
  font-size: 21px;
  font-weight: 700;
  color: rgb(113 115 119);
  letter-spacing: 0.5px;
}

.query-card-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 6px;
  background-color: rgb(48 202 144);
  left: 50%;
  bottom: -20px;
  transform: scaleX(1) translate(-50%, -50%);
  transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
          animation: expandCenter 1s ease-in-out;
}

.query-form-contact {
  padding-top: 48px;
  padding: 48px 48px;
}

@media screen and (max-width: 567.98px) {
  .query-card-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 992px) {
  .query-section-image {
    padding-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .card-section-query {
    padding-left: 45px;
  }
}

@media screen and (max-width: 575px) {
  .card-section-query .query-form {
    padding-left: 35px;
  }
}

@media screen and (max-width: 575px) {
  .query-form-contact {
    padding: 0px;
    padding-top: 48px;
  }
}

.container-not-found {
  width: 100%;
  height: 100%;
  min-height: 551px;
  position: relative;
  padding: 50px 0;
  background-color: rgba(225, 232, 245, 0.58);
}

.message-image > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding-top: 5%;
}

.message-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-404 h1 {
  font-size: 70px;
  font-weight: 800;
  color: #666666;
}

.message-404 strong {
  font-size: 20px;
  margin: 30px 0px;
  color: #03a9f4;
  font-weight: 600;
}

.message-404 .go-home-btn {
  padding: 10px 25px;
  background-color: rgb(250, 250, 250);
  border: 2px solid #2196f3;
  color: #2196f3;
  font-size: 20px;
  text-transform: uppercase;
  box-shadow: 0px 8px 16px #dedede;
  border-radius: 4px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.message-404 .go-home-btn:focus {
  outline: none;
}

.message-404 .go-home-btn:hover {
  background-color: #2196f3;
  border-color: transparent;
  color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px #a8a8a8;
}

@media screen and (max-width: 576.98px) {
  .message-404 .go-home-btn {
    padding: 8px 18px;
    font-size: 15px;
  }

  .message-404 strong {
    font-size: 16px;
  }

  .message-404 h1 {
    font-size: 55px;
  }
}

.scroll-fade-in {
  background-image: linear-gradient(
    to right top,
    #6bd17c,
    #00cfa8,
    #00c8d1,
    #00bced,
    #00adf7,
    #44a5f6,
    #659cf3,
    #7f93ec,
    #7993f0,
    #7193f3,
    #6994f7,
    #5f94fb
  );
  border: none;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  line-height: 5px;
  border-radius: 50%;
  z-index: 999;
  transition: opacity 1s ease-in;
  opacity: 1;
}
.scroll-fade-in,
.scroll-fade-out,
.scroll-fade-in:focus,
.scroll-fade-out:focus {
  outline: none;
}
.scroll-fade-in > svg,
.scroll-fade-out > svg {
  font-size: 40px;
}

.scroll-fade-in:hover > svg {
  -webkit-animation: bounce 1s infinite 0.5s ease-in-out;
          animation: bounce 1s infinite 0.5s ease-in-out;
}

.scroll-fade-out:hover > svg {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(-6px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(-6px);
  }
}

.scroll-fade-in:hover,
.scroll-fade-in:focus,
.scroll-fade-out:focus,
.scroll-fade-out:hover {
  outline: none;
}

.scroll-fade-out {
  background: rgb(98, 204, 223);
  border: none;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  line-height: 5px;
  border-radius: 50%;
  z-index: 999;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.be-tutor-fbtn {
  display: none;
  background-image: linear-gradient(120deg, #f16a3f 0%, #ff9800 100%);
  border: none;
  color: white;
  position: fixed;
  top: 85px;
  right: 20px;
  height: 50px;
  width: 120px;
  line-height: 5px;
  border-radius: 25px;
  z-index: 999;
  transition: all 1s ease-in-out;
  cursor: pointer;
  outline: none;

  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
}
.be-tutor-fbtn:focus {
  outline: none;
}
.be-tutor-fbtn > svg {
  font-size: 20px;
}

.be-tutor-fbtn:hover > svg {
  -webkit-animation: zoomInOut 1s infinite 0.5s ease-in-out;
          animation: zoomInOut 1s infinite 0.5s ease-in-out;
}

.scroll-fade-out:hover > svg {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes zoomInOut {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(1.1);
  }
}

@keyframes zoomInOut {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 1050.98px) {
  .be-tutor-fbtn {
    display: flex;
  }
}

.bg-thankyou-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.85);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.bg-thankyou-modal:target {
  visibility: visible;
  opacity: 0.7;
}

.success-img {
  max-width: 200px;
  width: 200px;
  height: auto;
  padding: 10px;
}

.bell-img {
  width: 40px;
  height: auto;
  position: absolute;
  left: 53%;
  top: 22.5%;
}

.bell-img {
  -webkit-animation: shake 1s infinite 1s;
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: shake 1s infinite 1s;
}

.thankyou-modal-content {
  position: relative;
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 3px 10px 11px #353535;
  padding: 15px;
}

.thankyou-header {
  display: flex;
  justify-content: flex-end;
}

.button-close {

  border: 0px transparent;
  background-color: transparent;
  color: rgb(128, 128, 128);
  padding-right: 10px;
}

.button-close:focus {
  outline: none;
}

.close-btn {
  font-size: 30px;
  font-weight: 900;
}

.close-btn:hover {
  color: rgb(0, 0, 0);
}

.msg-content {
  padding: 15px;
  margin-bottom: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(117, 125, 127);
}

.thankYou-close {

  text-decoration: none;
  padding: 15px 30px;

  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(27, 221, 27), rgb(56, 177, 8));
  color: rgb(245, 245, 245);
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 1px 1px 8px rgb(196, 196, 196);
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  font-size: 19px;
}

.thankyou-text {
  font-weight: 800;
  color: #00c8b7;
}

.thankYou-close:hover {
  box-shadow: 2px 10px 15px rgb(160, 160, 160);
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.thank-you-explore {
  margin-bottom: 60px;
}

@media(max-width:520.99px) {
  .thankyou-modal-content {
    margin: 0px 15px;

  }

  .thankYou-close {
    padding: 15px 70px;
  }

  .thankyou-text {
    font-size: 25px;
  }

  .msg-content {
    font-size: 14px;
  }

  .thankYou-close {
    font-size: 16px;
  }

  .success-img {
    width: 150px;
  }

  .bell-img {
    width: 50px;
    top: 21.5%;
  }
}

@media(max-width:349.99px) {
  .thankYou-close {
    padding: 15px 40px;
  }

  .success-img {
    width: 150px;
  }

  .bell-img {
    width: 40px;
    top: 16.5%;
  }

  .thankYou-close {
    font-weight: 600;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
}
.bg-error-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.85);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.bg-error-modal:target {
  visibility: visible;
  opacity: 0.7;
}

.error-img {
  max-width: 300px;
  width: 300px;
  height: auto;
  padding: 10px;
}

.bell-img {
  width: 40px;
  height: auto;
  position: absolute;
  left: 53%;
  top: 22.5%;
}

.bell-img {
  -webkit-animation: shake 1s infinite 1s;
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: shake 1s infinite 1s;
}

.error-modal-content {
  position: relative;
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 3px 10px 11px #353535;
  padding: 15px;
}

.error-header {
  display: flex;
  justify-content: flex-end;
}

.button-close {

  border: 0px transparent;
  background-color: transparent;
  color: rgb(128, 128, 128);
  padding-right: 10px;
}

.button-close:focus {
  outline: none;
}

.close-btn {
  font-size: 30px;
  font-weight: 900;
}

.close-btn:hover {
  color: rgb(0, 0, 0);
}

.err-msg-content {
  padding: 30px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: rgb(168, 168, 168);
  margin: 0px;
}

.error-close {

  text-decoration: none;
  padding: 15px 30px;

  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(111, 27, 221), rgb(8, 76, 177));
  color: rgb(255, 255, 255);
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 4px 6px 10px rgb(156, 146, 146);
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;

  font-size: 19px;
}

.error-text {
  font-weight: 800;
  color: #00c8b7;
}

.error-close:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.thank-you-explore {
  margin-bottom: 60px;
}

@media(max-width:520.99px) {
  .error-modal-content {
    margin: 0px 15px;

  }

  .error-close {
    padding: 15px 70px;
  }

  .error-text {
    font-size: 25px;
  }

  .msg-content {
    font-size: 14px;
  }

  .error-close {
    font-size: 16px;
  }

  .error-img {
    width: 200px;
  }

  .bell-img {
    width: 50px;
    top: 21.5%;
  }
}

@media(max-width:349.99px) {
  .error-close {
    padding: 15px 40px;
  }

  .error-img {
    width: 150px;
  }

  .bell-img {
    width: 40px;
    top: 16.5%;
  }

  .error-close {
    font-weight: 600;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
}
