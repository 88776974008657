.bg-error-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.85);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.bg-error-modal:target {
  visibility: visible;
  opacity: 0.7;
}

.error-img {
  max-width: 300px;
  width: 300px;
  height: auto;
  padding: 10px;
}

.bell-img {
  width: 40px;
  height: auto;
  position: absolute;
  left: 53%;
  top: 22.5%;
}

.bell-img {
  -webkit-animation: shake 1s infinite 1s;
  /* Safari 4+ */
  -moz-animation: shake 1s infinite 1s;
  /* Fx 5+ */
  -o-animation: shake 1s infinite 1s;
  /* Opera 12+ */
  animation: shake 1s infinite 1s;
}

.error-modal-content {
  position: relative;
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 3px 10px 11px #353535;
  padding: 15px;
}

.error-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}

.button-close {

  border: 0px transparent;
  background-color: transparent;
  color: rgb(128, 128, 128);
  padding-right: 10px;
}

.button-close:focus {
  outline: none;
}

.close-btn {
  font-size: 30px;
  font-weight: 900;
}

.close-btn:hover {
  color: rgb(0, 0, 0);
}

.err-msg-content {
  padding: 30px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: rgb(168, 168, 168);
  margin: 0px;
}

.error-close {

  text-decoration: none;
  padding: 15px 30px;

  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(111, 27, 221), rgb(8, 76, 177));
  color: rgb(255, 255, 255);
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 4px 6px 10px rgb(156, 146, 146);
  transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;

  font-size: 19px;
}

.error-text {
  font-weight: 800;
  color: #00c8b7;
}

.error-close:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.thank-you-explore {
  margin-bottom: 60px;
}

@media(max-width:520.99px) {
  .error-modal-content {
    margin: 0px 15px;

  }

  .error-close {
    padding: 15px 70px;
  }

  .error-text {
    font-size: 25px;
  }

  .msg-content {
    font-size: 14px;
  }

  .error-close {
    font-size: 16px;
  }

  .error-img {
    width: 200px;
  }

  .bell-img {
    width: 50px;
    top: 21.5%;
  }
}

@media(max-width:349.99px) {
  .error-close {
    padding: 15px 40px;
  }

  .error-img {
    width: 150px;
  }

  .bell-img {
    width: 40px;
    top: 16.5%;
  }

  .error-close {
    font-weight: 600;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
}