.related-courses-section {
    padding: 60px 0px;
}

.related-course-wrapper {
    position: relative;
    height: 350px;
    margin: 2.5rem 0;
    width: 100%;

}

.related-course-container {
    height: 100%;
    position: absolute;
    width: 100%;
    padding: 0 2.1em;
}

.related-course-carousel {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width:572px) {
    .related-course-wrapper {
        height: 300px;
        margin: 1.5rem 0rem !important;
        padding: 1rem 0.5rem !important;
    }

    .related-course-container {
        padding: 0px;
    }

    .related-course-carousel {
        max-width: 90%;
        margin: 0px auto !important;
    }
}