/* ************** */

article.course-list-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;
  flex: 0 0 auto;
  background: white;
}

article.course-list-card:hover {
  box-shadow: 2px 8px 11px rgb(206, 206, 206);
}

article.course-list-card .thumbnail {
  flex: 0 0 250px;
  border-radius: 3px;
  position: relative;
}

article.course-list-card .view-more {
  opacity: 0;
  color: rgb(3, 169, 244);
  flex: 0 0 100px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;
}

article.course-list-card:hover .view-more {
  opacity: 1;
}

article.course-list-card .thumbnail > img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 3px;
}

article.course-list-card .course-details {
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  padding: 14px 24px;
  background: rgb(255, 255, 255);
  -webkit-transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-list-card .course-details .course-title {
  position: relative;
  margin-bottom: 10px;
  color: rgb(21, 37, 54);
  font-size: 1.25rem;
  text-shadow: 0 0 0px rgb(50, 87, 127);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

article.course-list-card .course-details .course-card-rating {
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

article.course-list-card .course-details .course-card-rating > span {
  position: relative;
  top: 4px;
  margin-right: 10px;
}

article.course-list-card .course-details .more-info {
  position: relative;
}

article.course-list-card .course-details .course-summary {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  font-size: 16px;
  color: rgba(21, 37, 54, 0.7);
  padding-right: 10%;
  -webkit-transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-list-card .view-more:hover {
  background-color: #03a9f4;
  color: rgb(255, 255, 255);
}

article.course-list-card .view-more:hover .view-more-arrow {
  border-color: rgb(255, 255, 255);
}

article.course-list-card .view-more .view-more-arrow {
  font-weight: 100;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid rgb(3, 169, 244);
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  position: relative;
}

article.course-list-card .view-more .view-more-arrow > svg {
  font-weight: 100;
  position: absolute;
  top: 11px;
  left: 11px;
}

@media screen and (max-width: 767.98px) {
  article.course-list-card .thumbnail {
    display: none;
  }

  article.course-list-card .course-details .course-title {
    font-size: 1em;
  }

  article.course-list-card .course-details .course-summary {
    padding-right: 0%;
    font-size: 15px;
  }

  article.course-list-card .course-details {
    padding: 0px 7px;
  }
}

@media screen and (max-width: 991.98px) {
  article.course-list-card .view-more {
    display: none;
  }

  article.course-list-card .course-details .course-summary {
    padding: 0px;
  }
}
