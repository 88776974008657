#home-key-features-title {
  color: rgb(255, 255, 255);
}

.key-features {
  padding: 80px 0px !important;
  position: relative;
  background-image: url(../../../assets/images/keyFeaturesBG.jpg);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.73);
}

.key-features::after {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.73);
}

.key-features .rel-container {
  z-index: 9;
  position: relative;
}

.keyfeature-card-container {
  margin: 10px 0px;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 350px;
}

.keyfeature-card {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 350px;
  max-width: 350px;
  /* height: 100%; */
  margin: 15px;
  min-height: 280px;
  padding: 15px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  /* box-shadow: 3px 2px 8px rgb(0, 0, 0); */
}

.keyfeature-card:hover .keyfeature-image {
  -webkit-transition: 0.7s all;
  -webkit-transform: scale(1.2);
  -moz-transition: 0.7s all;
  -moz-transform: scale(1.2);
  -o-transition: 0.7s all;
  -o-transform: scale(1.2);
  transition: 0.7s all;
  transform: scale(1.2);
}

.keyfeature-card:hover .keyfeature-description {
  /* box-shadow: 3px 3px 13px rgb(0, 0, 0); */
  color: #fff;
}

.keyfeature-image {
  width: 150px;
  height: auto;
  max-width: 150px;
  padding: 15px 0px;
}

.keyfeature-card-title {
  color: rgb(255, 255, 255);
  text-align: -webkit-center;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
  padding-top: 15px;
}

.keyfeature-description {
  font-size: 17px;
  font-weight: 600;
  color: rgb(158, 158, 158);
  text-align: -webkit-center;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  -webkit-transition: 0.7s all;
  -moz-transition: 0.7s all;
  -o-transition: 0.7s all;
  transition: 0.7s all;
}

@media screen and (max-width: 576.98px) {
  .key-features {
    height: auto;
  }

  .key-features .rel-container > .container-fluid {
    width: 100%;
  }

  .keyfeature-card-container {
    padding-left: 0rem;
    padding-right: 0rem;
    height: 300px;
  }

  .keyfeature-card {
    height: auto;
    width: 100%;
    margin: 0px;
  }
}

@media screen and (max-width: 991.98px) {
  .keyfeature-card-container {
    text-align: center;
    text-align: -webkit-center;
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .key-features .rel-container > .container-fluid {
    width: 75%;
  }
}
