.discount-badge-container,
.discount-box {
  position: relative;
}

.discount-box {
  height: 250px;
}

.discount-badge-container p {
  position: absolute;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.disc-badge {
  width: 130px;
  position: absolute;
  right: 0px;
  height: 210px;
}

.discount-rate {
  right: 6px;
  font-size: 2.5em;
  top: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  width: 120px;
  text-align: center;
}

.disc-pc {
  position: absolute;
  width: 285px;
  left: -4px;
  top: 88px;
}
