/* ************** */

article.course-grid-card {
  width: 300px;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  -webkit-transition: 0.4s all ease;
  -moz-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

article.course-grid-card:hover {
  box-shadow: 2px 8px 11px rgb(206, 206, 206);
}

article.course-grid-card .thumbnail {
  text-align: -webkit-center;
  width: auto;
  height: 185px;
  border-radius: 3px;
  padding: 7px;
}

article.course-grid-card .thumbnail > img {
  width: 100%;
  height: 100%;
}

.gray-bg {
  width: 98%;
  height: 100%;
  border-radius: 3px;
  background-color: rgba(226, 226, 226, 0.4);
}

article.course-grid-card .course-details {
  width: auto;
  height: 300px;
  position: relative;
  border-radius: 3px;
  padding: 14px 24px;
  background: rgb(255, 255, 255);
  -webkit-transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  -moz-transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  -o-transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  transition: 0.4s 0.05s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-grid-card .course-details .course-title {
  position: relative;
  margin: 0px;
  color: rgb(21, 37, 54);
  font-size: 1.25rem;
  text-shadow: 0 0 0px rgb(50, 87, 127);
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  orientation: portrait;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

article.course-grid-card .course-details .course-card-rating {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: -2px;
}

article.course-grid-card .course-details .course-card-rating > span {
  position: relative;
  top: 4px;
  margin-right: 10px;
}

article.course-grid-card .course-details .more-info {
  display: none;
  opacity: 0;

  position: relative;
}

article.course-grid-card .course-details .course-summary {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 5;
  orientation: portrait;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  font-size: 17px;
  color: rgba(43, 43, 44, 0.7);
  margin-bottom: 10px;
  -webkit-transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
  transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

article.course-grid-card .course-details .view-more-option {
  padding: 7px 14px;
  text-align: center;
  text-align: -webkit-center;
  background-color: rgb(240, 248, 255);
  color: rgb(0, 123, 255);
  font-weight: 600;
  border: 0px;
  box-shadow: 2px 2px 9px rgb(226, 226, 226);
  outline: 0px;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
}

article.course-grid-card .course-details .view-more-option:hover {
  box-shadow: 2px 2px 9px rgb(207, 207, 207);
  text-decoration: underline;
}

/* article.course-grid-card:focus-within .course-details,
article.course-grid-card:focus .course-details, */
article.course-grid-card:hover .course-details {
  -webkit-transform: translateY(-185px);
  -moz-transform: translateY(-185px);
  -o-transform: translateY(-185px);
  transform: translateY(-185px);
}

/* article.course-grid-card:focus-within .course-details .more-info,
article.course-grid-card:focus .course-details .more-info, */
article.course-grid-card:hover .course-details .more-info {
  display: block;
  opacity: 1;
}

@media screen and (max-width: 991.98px) {
  article.course-grid-card {
    margin: 10px 20px;
  }
}

@media screen and (max-width: 600px) {
  article.course-grid-card:hover .course-details {
    transform: translateY(-170px);
  }

  article.course-grid-card .course-details .course-title {
    font-size: 1em;
  }

  article.course-grid-card .course-details .course-summary {
    padding-right: 0%;
    font-size: 15px;
    line-clamp: 4;
    -webkit-line-clamp: 3;
  }

  article.course-grid-card .course-details {
    padding: 0px 7px;
  }

  article.course-grid-card:hover .course-details {
    padding: 14px 24px;
  }

  article.course-grid-card .thumbnail {
    max-height: 200px;
  }

  article.course-grid-card {
    width: 250px;
    max-height: 270px;
    margin: 6px;
  }
}
