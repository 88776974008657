.container-not-found {
  width: 100%;
  height: 100%;
  min-height: 551px;
  position: relative;
  padding: 50px 0;
  background-color: rgba(225, 232, 245, 0.58);
}

.message-image > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding-top: 5%;
}

.message-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message-404 h1 {
  font-size: 70px;
  font-weight: 800;
  color: #666666;
}

.message-404 strong {
  font-size: 20px;
  margin: 30px 0px;
  color: #03a9f4;
  font-weight: 600;
}

.message-404 .go-home-btn {
  padding: 10px 25px;
  background-color: rgb(250, 250, 250);
  border: 2px solid #2196f3;
  color: #2196f3;
  font-size: 20px;
  text-transform: uppercase;
  box-shadow: 0px 8px 16px #dedede;
  border-radius: 4px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.message-404 .go-home-btn:focus {
  outline: none;
}

.message-404 .go-home-btn:hover {
  background-color: #2196f3;
  border-color: transparent;
  color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px #a8a8a8;
}

@media screen and (max-width: 576.98px) {
  .message-404 .go-home-btn {
    padding: 8px 18px;
    font-size: 15px;
  }

  .message-404 strong {
    font-size: 16px;
  }

  .message-404 h1 {
    font-size: 55px;
  }
}
