.section-title {
  text-align: -webkit-center;
  text-align: center;
}

.oep-home-section {
  padding: 60px 0px;
}
.oep-home-section.first-banner {
  padding: 0px;
  position: relative;
  display: flex;
}
.first-banner .carousel-item {
  min-height: 800px;
}
.first-banner .carousel-inner,
.first-banner #home-main {
  height: 100%;
}
.homepg-bg {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  width: 100vw;
}

#home-main .carousel-inner img {
  width: 100%;
  height: 100%;
}
.first-banner .overlay-bg,
.first-banner .abs-parent {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.first-banner .scroll-down {
  position: absolute;
  bottom: 7%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.first-banner .scroll-down > small {
  margin-top: 5px;
  font-weight: 100;
  font-size: 13px;
}
.first-banner .scroll-down-btn {
  padding: 7px 12px;
  border-radius: 30px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 17px;
}
.first-banner .scroll-down-btn > svg {
  animation: jump 1s infinite ease-in-out;
}
@keyframes jump {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(0px);
  }
}
.first-banner .overlay-bg {
  background-color: rgb(0 0 0 / 73%);
}
.first-banner .main-title {
  position: absolute;
  width: 100%;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -38%);
  color: white;
  z-index: 99;
  font-weight: 300;
  text-align: center;
  font-size: 4vw;
  padding: 0px 10px;
  white-space: nowrap;
  overflow: hidden;
}
.why-we-here {
  background: -webkit-linear-gradient(
    45deg,
    rgb(0, 0, 0),
    #163283,
    rgb(14, 107, 210)
  );
  background: -moz-linear-gradient(
    45deg,
    rgb(0, 0, 0),
    #163283,
    rgb(14, 107, 210)
  );
  background: -o-linear-gradient(
    45deg,
    rgb(0, 0, 0),
    #163283,
    rgb(14, 107, 210)
  );
  background: linear-gradient(45deg, rgb(0, 0, 0), #163283, rgb(14, 107, 210));
  color: #fff;
}

.why-we-here-container {
  position: relative;
  text-align: center;
}

.why-we-here-img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 15px 50px 30px;
}

@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .why-we-here-img {
    height: 100%;
    padding: 50px 0px !important;
  }
}

.why-we-here-content {
  font-weight: 500;
  padding: 45px 0px 10px 0px;
  font-size: 21px;
  text-align: justify;
}

#home-whywehere-title {
  color: rgb(255, 255, 255);
}

.why-we-here-list {
  list-style: disc;
  text-align: left;
  padding-left: 40px;
  font-size: 21px;
  font-weight: 600;
  color: #ffffff;
}
.why-we-here-list > li {
  margin-bottom: 6px;
}
.svg-section {
  height: 180px;
  width: 100%;
}

.svg-section img {
  width: 100%;
}

/* Section title styles */
.section-title {
  text-align: -webkit-center;
  text-align: center;
  color: rgb(33, 150, 243);
  position: relative;
  font-weight: 700;
}

.section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 25%;
  bottom: -20px;
  -webkit-transform: scaleX(1);
  -webkit-transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
  -moz-transform: scaleX(1);
  -moz-transition: transform 250ms ease-in-out;
  -moz-animation: expandCenter 1s ease-in-out;
  -o-transform: scaleX(1);
  -o-transition: transform 250ms ease-in-out;
  -o-animation: expandCenter 1s ease-in-out;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
}

@keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-moz-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@-o-keyframes expandCenter {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.main-content-heading {
  color: #1e7dec;
  padding-bottom: 1.5rem;
  padding-right: 2em;
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 1.3px;
}

.main-content-detail {
  padding-bottom: 0.5em;
  font-size: 19px;
  letter-spacing: 1px;
  font-weight: 400;
  color: rgb(88, 88, 88);
}

.main-content-button {
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 20px;
  margin-top: 15px;
  max-width: 160px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  background-color: transparent;
  border: 2px solid white;
  box-shadow: 1px 9px 11px rgb(29 28 28 / 82%);
  overflow: hidden;
  outline: none;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

.main-content-button span {
  z-index: 20;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.main-content-button::after {
  background: rgb(255, 255, 255);
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.2;
  position: absolute;
  top: -50px;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
  z-index: 10;
}

.main-content-button:hover span {
  font-weight: 500;
}

.main-content-button:hover::after {
  left: 100%;
  -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

.main-content-button:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: #0070f7;
  box-shadow: 4px 5px 12px rgb(24, 23, 23);
  border-color: transparent;
}

.main-content-button:hover > .interested-icon {
  -webkit-animation: moveLeftRight 0.8s infinite ease-in-out;
  -moz-animation: moveLeftRight 0.8s infinite ease-in-out;
  -o-animation: moveLeftRight 0.8s infinite ease-in-out;
  animation: moveLeftRight 0.8s infinite ease-in-out;
}

@keyframes moveLeftRight {
  from {
    right: 20px;
  }

  to {
    right: 5px;
  }
}

.interested-icon {
  padding-left: 6px;
  position: absolute;
  right: 20px;
}
.main-theme-content {
  align-self: center;
}
.main-theme-img {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding: 5%;
}

#query-section {
  background-color: rgb(250, 250, 250);
  background: rgb(250, 250, 250);
}

.query-section-title {
  color: rgb(0, 0, 0);
  position: relative;
  font-weight: 700;
}

.query-section-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(90, 151, 66);
  left: 0%;
  bottom: -20px;
  -webkit-transform: scaleX(1);
  -webkit-transition: transform 250ms ease-in-out;
  -webkit-animation: expandCenter 1s ease-in-out;
  -moz-transform: scaleX(1);
  -moz-transition: transform 250ms ease-in-out;
  -moz-animation: expandCenter 1s ease-in-out;
  -o-transform: scaleX(1);
  -o-transition: transform 250ms ease-in-out;
  -o-animation: expandCenter 1s ease-in-out;
  transition: transform 250ms ease-in-out;
  transform: scaleX(1);
  animation: expandCenter 1s ease-in-out;
}

.home-popular-courses {
  font-family: "Trebuchet MS", Arial, Tahoma, Serif;
}

.card-section {
  margin-top: 30px;
  border-radius: 5px;
  background-color: rgb(250, 250, 250);
  width: 100%;
  padding: 15px;
}

.query-theme-img {
  max-width: 100%;
  height: auto;
  width: 100%;
  padding-top: 15%;
}

@media (max-width: 990px) and (min-width: 775px) {
  .main-content-detail {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 990px) and (min-width: 775px) {
  .svg-section {
    height: 120px;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .svg-section {
    height: 80px;
    width: 100%;
  }
  .first-banner .carousel-item {
    min-height: 500px;
  }
}

@media (max-width: 576px) {
  .first-banner .main-title {
    font-size: 2em;
    white-space: unset;
    top: 32%;
    transform: translate(-50%, -32%);
  }
  .first-banner .main-content-button {
    margin-top: 15%;
  }
}
