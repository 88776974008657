/* popular courses styles */

/* Popular course Header */
.pop-title-container {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  justify-content: -webkit-center;
  flex-direction: column;
  margin-right: 1.5em;
  width: 100%;
}

.pop-wrapper {
  margin: 2.5rem 0rem;
  padding: 0rem 2.5rem;
}

.pop-course-container {
  height: 350px;
}

/* Popular course Header styles */
.pop-course-title {
  margin-bottom: 15px;
  line-height: 1;
  color: #1a7adb;
  position: relative;
  font-weight: 700;
  font-size: 2em;
}

.pop-course-title::after {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  border-radius: 15px;
  height: 5px;
  background-color: rgb(255, 174, 73);
  left: 0%;
  bottom: -20px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  -webkit-transition: transform 250ms ease-in-out;
  -moz-transition: transform 250ms ease-in-out;
  -o-transition: transform 250ms ease-in-out;
  animation: expandToRight 1s ease-in-out;
  -webkit-animation: expandToRight 1s ease-in-out;
  -moz-animation: expandToRight 1s ease-in-out;
  -o-animation: expandToRight 1s ease-in-out;
}

@keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@-moz-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

@-o-keyframes expandToRight {
  from {
    width: 0%;
  }

  to {
    width: 50%;
  }
}

/*  */
.pop-course-loadmore {
  text-decoration: none;
  width: 170px;
  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(27, 124, 221), rgb(8, 92, 177));
  border: 1px solid transparent;
  color: rgb(255, 255, 255);
  font-weight: 500;
  padding: 7px 20px;
  font-size: 1.2em;
  cursor: pointer;
  letter-spacing: 0.5px;
  box-shadow: 0px 4px 10px rgb(202, 202, 202);
  outline: none;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  position: relative;
  left: 10px;
  top: 35px;
}
.pop-course-loadmore:focus {
  outline: none;
}
.pop-course-loadmore:hover {
  text-decoration: none;
  color: rgb(255, 255, 255);
  box-shadow: 3px 8px 10px rgb(202, 202, 202);
}

.pop-course-info {
  padding: 20px 0px;
  border-radius: 8px;
  color: rgb(105, 105, 105);
  padding-bottom: 30px;
}
.next-course-btn,
.prev-course-btn {
  background-color: rgb(255, 255, 255);
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  z-index: 99;
  box-shadow: 0px 3px 11px rgb(144, 144, 144);
  position: absolute;
  top: calc(50% - 30px);
  transition: 0.5s all ease-in-out;
  -webkit-transition: 0.5s all ease-in-out;
  -moz-transition: 0.5s all ease-in-out;
  -o-transition: 0.5s all ease-in-out;
  outline: none;
}
.next-course-btn:focus,
.prev-course-btn:focus {
  outline: none;
}
.next-course-btn {
  right: -45px !important;
}

.prev-course-btn {
  left: -40px !important;
}

.next-course-btn:hover,
.prev-course-btn:hover {
  background-color: rgb(33, 150, 243) !important;
  border: 1px solid rgb(169, 169, 169);
  box-shadow: 1px 1px 10px rgb(192, 192, 192);
}

.next-course-btn:hover svg,
.prev-course-btn:hover svg {
  color: rgb(245, 245, 245) !important;
}

.prev-course-btn > svg,
.next-course-btn > svg {
  color: rgb(33, 150, 243);
  font-size: 22px;
  position: absolute;
  top: 8px;
  left: 10px;
}

.next-course-btn > svg {
  left: 13px;
}

/* customizing slick */
.slick-active > div:focus {
  outline: none !important;
}

.slick-list,
.slick-track {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .pop-course-container {
    height: 300px;
  }

  .pop-wrapper {
    margin: 1.5rem 0rem !important;
  }
}

@media screen and (max-width: 991.98px) {
  .pop-wrapper {
    padding: 0rem 2.3rem;
  }

  .pop-title-container {
    text-align: center;
    text-align: -webkit-center;
    align-items: center;
  }

  .pop-course-title {
    text-align: center;
    text-align: -webkit-center;
    font-size: 2rem;
  }

  .pop-course-title::after {
    left: 25%;
    bottom: -20px;
    animation: expandCenter 1s ease-in-out;
    -webkit-animation: expandCenter 1s ease-in-out;
    -moz-animation: expandCenter 1s ease-in-out;
    -o-animation: expandCenter 1s ease-in-out;
  }

  .pop-course-loadmore {
    align-self: center;
  }
}
