.social-snack-bar {
  position: fixed;
  width: 65px;
  top: 40%;
  left: 4px;
  border-radius: 5px;
  z-index: 99;
}

.social-links-container {
  margin: 0px;
  list-style-type: none;
  padding: 10px;
}

.social-links-container .social-link-nav {
  padding: 23px;
  cursor: pointer;
  border-radius: 30px;
  margin: 8px 0px;
  position: relative;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  height: 46px;
  width: 46px;
}

.social-links-container .social-link-nav > a {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #565656;
  cursor: pointer;
}
.social-links-container .social-link-nav > a > svg {
  color: #fff;
  font-size: 1.6em;
}

.social-link-info {
  display: none;
  position: absolute;
  opacity: 0;
  height: 46px;
  width: 350px;
  font-size: 15px;
  font-family: sans-serif;
  color: #565656;
  padding: 12px 15px;
  padding-left: 60px;
  left: 1px;
  top: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  z-index: -1;
  border: 1px solid rgb(229, 229, 229);
}

.social-links-container .social-link-nav:hover svg {
  font-size: 1.5em;
  transform: scale(1.2);
  -webkit-animation: ScaleUp 0.7s ease-in-out;
  -moz-animation: ScaleUp 0.7s ease-in-out;
  -o-animation: ScaleUp 0.7s ease-in-out;
  animation: ScaleUp 0.7s ease-in-out;
}

.social-links-container .social-link-nav.whatsapp-link svg,
.social-links-container .social-link-nav.whatsapp-link:hover svg {
  font-size: 1.7em;
}

.social-link-nav.whatsapp-link {
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
}

.social-link-nav.facebook-link {
  background-color: rgb(59, 89, 153);
  color: rgb(255, 255, 255);
}
.social-link-nav.youtube-link {
  background-color: rgb(214, 28, 28);
  color: rgb(255, 255, 255);
}
.social-link-nav.insta-link {
  background: rgb(228, 64, 95);
  color: rgb(255, 255, 255);
}

.social-link-nav.twitter-link {
  background-color: rgb(85, 172, 238);
  color: rgb(255, 255, 255);
}

.social-link-nav.LinkedIn-link {
  background-color: rgb(0, 119, 181);
  color: rgb(255, 255, 255);
}

.social-link-nav.whatsapp-link:hover {
  background-color: rgb(37, 211, 102);
  color: rgb(255, 255, 255);
}

.social-link-nav.facebook-link:hover {
  background-color: rgb(59, 89, 153);
  color: rgb(255, 255, 255);
}

.social-link-nav.insta-link:hover {
  background-color: rgb(228, 64, 95);

  color: rgb(255, 255, 255);
}

.social-link-nav.twitter-link:hover {
  background-color: rgb(85, 172, 238);
  color: rgb(255, 255, 255);
}

.social-link-nav.LinkedIn-link:hover {
  background-color: rgb(0, 119, 181);
  color: rgb(255, 255, 255);
}

@keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
@-webkit-keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
@-moz-keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
@-o-keyframes ScaleUp {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
