.bg-thankyou-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(53, 53, 53, 0.85);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.bg-thankyou-modal:target {
  visibility: visible;
  opacity: 0.7;
}

.success-img {
  max-width: 200px;
  width: 200px;
  height: auto;
  padding: 10px;
}

.bell-img {
  width: 40px;
  height: auto;
  position: absolute;
  left: 53%;
  top: 22.5%;
}

.bell-img {
  -webkit-animation: shake 1s infinite 1s;
  /* Safari 4+ */
  -moz-animation: shake 1s infinite 1s;
  /* Fx 5+ */
  -o-animation: shake 1s infinite 1s;
  /* Opera 12+ */
  animation: shake 1s infinite 1s;
}

.thankyou-modal-content {
  position: relative;
  width: 500px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  box-shadow: 3px 10px 11px #353535;
  padding: 15px;
}

.thankyou-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
}

.button-close {

  border: 0px transparent;
  background-color: transparent;
  color: rgb(128, 128, 128);
  padding-right: 10px;
}

.button-close:focus {
  outline: none;
}

.close-btn {
  font-size: 30px;
  font-weight: 900;
}

.close-btn:hover {
  color: rgb(0, 0, 0);
}

.msg-content {
  padding: 15px;
  margin-bottom: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(117, 125, 127);
}

.thankYou-close {

  text-decoration: none;
  padding: 15px 30px;

  background-color: rgb(15, 108, 200);
  background: linear-gradient(to right, rgb(27, 221, 27), rgb(56, 177, 8));
  color: rgb(245, 245, 245);
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 1px 1px 8px rgb(196, 196, 196);
  transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  font-size: 19px;
}

.thankyou-text {
  font-weight: 800;
  color: #00c8b7;
}

.thankYou-close:hover {
  box-shadow: 2px 10px 15px rgb(160, 160, 160);
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.thank-you-explore {
  margin-bottom: 60px;
}

@media(max-width:520.99px) {
  .thankyou-modal-content {
    margin: 0px 15px;

  }

  .thankYou-close {
    padding: 15px 70px;
  }

  .thankyou-text {
    font-size: 25px;
  }

  .msg-content {
    font-size: 14px;
  }

  .thankYou-close {
    font-size: 16px;
  }

  .success-img {
    width: 150px;
  }

  .bell-img {
    width: 50px;
    top: 21.5%;
  }
}

@media(max-width:349.99px) {
  .thankYou-close {
    padding: 15px 40px;
  }

  .success-img {
    width: 150px;
  }

  .bell-img {
    width: 40px;
    top: 16.5%;
  }

  .thankYou-close {
    font-weight: 600;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
}