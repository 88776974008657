.process-card-container {
    margin: 10px 0px;
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
}

.process-card {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -ms-flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 280px;
    padding: 15px;
    border-radius: 7px;
    background-color: rgb(255, 255, 255);
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
    box-shadow: 1px 1px 10px rgb(212, 212, 212);
}

.process-card:hover {
    box-shadow: 4px 7px 20px rgb(207, 207, 207);
}

.process-card .process-step-badge {
    position: absolute;
    left: 12px;
    width: 30px;
    height: 30px;
    margin: 0px;
    border-radius: 50%;
    background-color: rgb(255, 117, 107);
    padding-left: 11px;
    padding-top: 5px;
    color: rgb(255, 255, 255);
    font-size: 0.8em;
    box-shadow: 1px 2px 8px rgb(173, 173, 173);
}

.process-image {
    width: 75px;
    height: auto;
    max-width: 100px;
    padding: 15px 0px;
}

.process-card-title {
    width:100%;
    color: rgb(255, 70, 143);
    font-weight: 700;
    margin-bottom: 15px;
    padding-top: 15px;
    text-align: -webkit-center;
    text-align: center;
}

#a {
    scroll-behavior: smooth;
}

.process-description {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: rgb(131, 131, 131);
    text-align: -webkit-center;
    text-align: center;
    letter-spacing: 0.5px;
}

.process-query-qstn {
    color: rgb(134, 134, 134);
    font-size: 1.2em;
    padding: 10px 20px;
    letter-spacing: 0.7px;

}

.process-query-btn:focus {
    outline: none;
}

.process-query-btn {
    background: linear-gradient(to right, rgb(255, 114, 152), rgb(255, 95, 97));
    background-color: rgb(255, 95, 97);
    border: 1px solid transparent;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding: 7px 20px;
    font-size: 1.2em;
    cursor: pointer;
    letter-spacing: 0.5px;
    box-shadow: 0px 4px 10px rgb(202, 202, 202);
    outline: none;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;
}

.process-query-btn:hover {
    box-shadow: 3px 8px 10px rgb(202, 202, 202);
}


@media screen and (max-width:576.98px) {
    .process-card-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width:767.98px) {
    .query-model-section {
        flex-direction: column;
        -ms-flex-direction: column;
    }

    .process-query-qstn {
        margin-bottom: 15px;
    }
}