.course-detail-main {
  display: flex;
  display: -webkit-flex;
  padding: 0px 50px;
}

.mini-query-section,
.duration-box,
.discount-box {

  width: 100%;
}

@media screen and (min-width:1399.98px) {
  .course-detail-main {
    padding: 0px 100px;
    width: 90%;
    margin: 0px auto;
  }
}

@media screen and (max-width:761.98px) {
  .course-detail-main {
    padding: 0em 1em;

  }

}

@media screen and (max-width:1199.98px) {
  .course-detail-main .right-container {
    display: none !important;
  }
}

@media screen and (max-width:1199.98px) {
  .course-detail-main .right-container {
    display: none !important;
  }
}

.course-detail-main .left-container {
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.course-detail-main .right-container {
  display: block;
  flex: 0 0 400px;
  position: relative;

}

.course-detail-main .right-container .wrapper {
  position: relative;
  max-width: 400px;

}

.course-section-title {
  display: inline-block;
  color: black;
  font-size: 1.5rem;
  position: relative;
  font-weight: 700;
}

.course-section-title::after {
  content: "";
  display: block;
  position: relative;
  width: 100%;
  border-radius: 15px;
  height: 4px;
  background-color: orange;
  left: 0%;
  bottom: -10px;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  animation: expandCenter 1s ease-in-out;
}